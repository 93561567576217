import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  twitterClicked() {
    window.open('https://twitter.com/HomeTeam_Live', '_blank');
  }

  youtubeClicked() {
    window.open('https://www.youtube.com/channel/UCJxUxPH8YxKudNwSpI12MAw', '_blank');
  }

  linkedinClicked() {
    window.open('https://www.linkedin.com/company/hometeamlive/', '_blank');
  }

  instagramClicked() {
    window.open('https://www.instagram.com/hometeamlive/', '_blank');
  }

  tikTokClicked() {
    window.open('https://www.tiktok.com/@hometeamlive', '_blank');
  }

  faqClicked() {
    window.open('https://www.hometeamlive.com/faq', '_blank');
  }

  helpClicked() {
    window.open('https://www.hometeamlive.com/support', '_blank');
  }

  touClicked() {
    window.open('https://www.hometeamlive.com/terms', '_blank');
  }

  privacyClicked() {
    window.open('https://www.hometeamlive.com/privacy', '_blank');
  }

  logoClicked() {
    window.open('https://app.hometeamlive.com/#/home');
  }

  getDate(): string{
    return new Date().getFullYear().toString();
  }
}
