import { BaseModel } from '../../base/base-model';

export class CreateContributorRequest extends BaseModel{
    id: number;
    public email: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public active: boolean = true;
    public timeStampVal: Date;
    public contributorCategoryIds: number[]=[];
    public timeKeeperContributor: number;
    public scoreKeeperContributor: number;
    public cameraSetupContributor: number;
    public leagueId: number;

    itemChanged: boolean;
    itemCreated: boolean;
    itemDeleted: boolean;


    static intiWithContributor(contributer: CreateContributorRequest): CreateContributorRequest{
        const formObject=new CreateContributorRequest();
        formObject.id=contributer.id;
        formObject.firstName=contributer.firstName;
        formObject.lastName=contributer.lastName;
        formObject.active=contributer.active;
        formObject.contributorCategoryIds=contributer.contributorCategoryIds;
        formObject.timeStampVal=contributer.timeStampVal;
        formObject.email=contributer.email;

        return formObject;
    }

    public onDeserialize() {
        super.onDeserialize();
      }
}
