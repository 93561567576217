import {BehaviorSubject} from 'rxjs';
import {LoadingSpinnerSize} from '../enum/shared/loading-spinner-size.enum';
import {debounceTime} from 'rxjs/operators';

export class LoadingOptions {
  public isLoading: boolean = false;
  public fullscreen: boolean = false;
  public determinateLoading: boolean = false;
  public showLoadingText: boolean = false;
  public progress = 0;
  public loadingText: string;
  public backgroundColor: string;
  public spinnerColor: string;
  public color: string;
  public topMarginRem: number = 0;
  public cornerRadius: string;
  public spinnerSize: LoadingSpinnerSize = LoadingSpinnerSize.Default;
  public zIndex = 99;
  public debounceTime = 0;
  private awaitingRequests: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(debounceTimeMillis: number = 0) {
    this.debounceTime = debounceTimeMillis;
  }


  static default(determinate: boolean = false, fullscreen: boolean = false, debounceTimeMillis: number = 0): LoadingOptions {
    const opt = new LoadingOptions(debounceTimeMillis);
    opt.backgroundColor = '#C3C9D0';
    opt.spinnerColor = '#D81F27';
    opt.color = '#D81F27';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    opt.init();
    return opt;
  }

  static defaultLight(determinate: boolean = false,
                      fullscreen: boolean = false,
                      showOnInit: boolean = true,
                      debounceTimeMillis: number = 0): LoadingOptions {
    const opt = LoadingOptions.default(determinate, fullscreen, debounceTimeMillis);
    opt.backgroundColor = '#FEFEFE';
    opt.spinnerColor = '#D81F27';
    opt.color = '#D81F27';
    opt.isLoading = showOnInit;
    return opt;
  }

  static defaultTransparent(determinate: boolean = false,
                            fullscreen: boolean = false,
                            showOnInit: boolean = true,
                            debounceTimeMillis: number = 0): LoadingOptions {
    const opt = LoadingOptions.default(determinate, fullscreen, debounceTimeMillis);
    opt.backgroundColor = '#00000000';
    opt.spinnerColor = '#D81F27';
    opt.color = '#D81F27';
    opt.isLoading = showOnInit;
    return opt;
  }

  static defaultInButton(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#FFF';
    opt.color = '#FFF';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    opt.init();
    return opt;
  }

  init() {
    this.awaitingRequests.pipe(debounceTime(this.debounceTime)).subscribe((reqs) => {
      setTimeout(() => {
        this.isLoading = reqs.length > 0;
        if (reqs.length > 0) {
          this.loadingText = reqs[reqs.length - 1];
        } else {
          this.loadingText = '';
        }
      });
    });
  }

  containsRequest(mess: string): boolean {
    const currReqs = this.awaitingRequests.getValue();
    const existingIndex = currReqs.indexOf(mess);
    return existingIndex > -1;
  }

  addRequest(mess: string) {
    const currReqs = this.awaitingRequests.getValue();
    const existingIndex = currReqs.indexOf(mess);
    if (existingIndex === -1) {
      currReqs.push(mess);
      this.awaitingRequests.next(currReqs);
    }
  }

  removeRequest(mess: string) {
    const currReqs = this.awaitingRequests.getValue();
    const removeIndex = currReqs.indexOf(mess);
    if (removeIndex > -1) {
      currReqs.splice(removeIndex, 1);
    }
    this.awaitingRequests.next(currReqs);
  }

  connectToRequestQueue(): BehaviorSubject<string[]> {
    return this.awaitingRequests;
  }
}
