import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {SearchDomainModel} from '../../../../domainModels/search-domain-model';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {debounceTime, filter, map} from 'rxjs/operators';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {SearchListable} from '../../../../models/protocols/search-listable';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import Fuse from 'fuse.js';
import {SearchListItemContainer} from './search-list-item-container';
import { HydratedVenue } from 'src/app/models/resources/hydrated-venue';
import { HydratedLeague } from 'src/app/models/resources/hydrated-league';
import { HydratedEvent } from 'src/app/models/resources/hydrated-event';
import { HydratedTeam } from 'src/app/models/resources/hydrated-team';

@Injectable()
export class SearchModalViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  searchText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  searchListItems$: Observable<Fuse<SearchListItemContainer>> = combineLatest([
    this.resourceDomainModel.activeHydratedEvents$,
    this.resourceDomainModel.activeHydratedLeagues$,
    this.resourceDomainModel.activeHydratedVenues$,
    this.resourceDomainModel.activeHydratedTeams$
  ]).pipe(
    filter(results => results.every(r => !!r)),
    indicateOnNext(this.loadingOpts, ''),
    map((results) => {
      const allResults = results.flatMap(r => r ?? []);
      return SearchListItemContainer.generateFuse(allResults);
    })
  );

  searchResults$: Observable<SearchListable[]> = combineLatest([
    this.searchText$,
    this.searchListItems$,
    this.resourceDomainModel.activeHydratedLeagues$,
    this.resourceDomainModel.activeHydratedEvents$,  this.resourceDomainModel.activeHydratedVenues$,
    this.resourceDomainModel.activeHydratedTeams$
  ]).pipe(
    debounceTime(200),
    map(([searchText, searchFuse, leagues,events,venues,teams]) => {
      if (!searchText) {
        return events; // show events by default
      }

  const searchResults = searchFuse.search(searchText, { limit: 10 }).map(r => r.item.searchListableValue);

  // Filter and order results by category, starting with events
  const eventResults = searchResults
  .filter(result => result instanceof HydratedEvent)
  .filter(result =>
    events.some(event => event.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
  );

const leagueResults = searchResults
  .filter(result => result instanceof HydratedLeague)
  .filter(result =>
    leagues.some(league => league.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
  );

const venueResults = searchResults
  .filter(result => result instanceof HydratedVenue)
  .filter(result =>
    venues.some(venue => venue.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
  );

const teamResults = searchResults
  .filter(result => result instanceof HydratedTeam)
  .filter(result =>
    teams.some(team => team.getSearchListablePrimaryKey() === result.getSearchListablePrimaryKey())
  );
  const totalResults = [...eventResults, ...leagueResults,...venueResults,  ...teamResults].slice(0, 10);

  return totalResults;

    })
  );

  constructor(private domainModel: SearchDomainModel, private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }
}

