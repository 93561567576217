import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseModel } from '../base/base-model';
import { DatatableData } from '../protocols/datatable-data';
import { SearchListable } from '../protocols/search-listable';
import { Selectable } from '../protocols/selectable';
import { FormListable } from '../protocols/form-listable';

export class contributor extends BaseModel implements Selectable, DatatableData, SearchListable, FormListable
{

    id: number = 0;
    email: string = null;
    firstName: string = null;
    lastName: string = null;
    active: boolean = true;
    contributorCategoryIds: number[] = [];
    leagueId: number = 0;
    eventId: number = 0;

    itemChanged: boolean;
    itemDeleted: boolean;
    itemCreated: boolean;
    onDeserialize() {
        super.onDeserialize();
}
    getListItemTitle(): string {
       return this.firstName;
    }
    getListItemSubtitle(): string {
        return this.firstName;
    }
    getEnabledStatus(): boolean {
        return true;
    }


    getSearchListableTitle(): string {
        return this.firstName;
    }
    getSearchListableSubTitle(): string {
        return this.firstName;
    }
    getSearchListablePrimaryKey(): string {
        return this.firstName;
    }
    getSearchListableSecondaryKey(): string {
        return this.firstName;
    }

    getChildrenUniqueIds(): string[] {
        return [];
    }
    getColor(): string {
        return '';
    }
    getTextColor(): string {
        return '';
    }
    getSelectionTitle(): string {
       return this.firstName;
    }
    getSelectionValue() {
       return this.id;
    }
    getSelectionUniqueIdentifier() {
       return this.id;
    }
    getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
       return null;
    }


}
