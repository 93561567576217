<div [class.pre-roll-ad-playing]="viewModel.playingPreRollAd" class="ivs-video-player-container">
  <video
    id="video-js-target"
    class="video-js"
    playsinline
    controls
    preload="auto">
  </video>

  <div class="score-bug-overlay" *ngIf=" isStartedScoring$ | async">
    <ng-container *ngIf="isLive$ | async">
      <app-score-bug
        [homeTeamName]="homeTeamName || 'Home'"
        [homeTeamScore$]="homeTeamScore$"
        [visitingTeamScore$]="visitingTeamScore$"
        [visitingTeamName]="visitingTeamName || 'Away'">
      </app-score-bug>
    </ng-container>
  </div>
</div>
