import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {BehaviorSubject, combineLatest, interval, Observable, of, throwError} from 'rxjs';
import {ContentQuery} from '../../../../models/program/content-query';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take} from 'rxjs/operators';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {ProgramDomainModel} from '../../../../domainModels/program-domain-model';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ToastService} from '../../../../services/toast-service';
import {ProgramComment} from '../../../../models/program/program-comment';
import {Program} from '../../../../models/program/program';
import * as moment from 'moment';
import {NumberUtils} from '../../../../utils/number-utils';
import {environment} from '../../../../../environments/environment';
import {AuthFlow} from '../../../../models/account/enum/auth-flow.enum';
import {SessionService} from '../../../../services/session-service';
import {HydratedShow} from '../../../../models/program/hydrated-show';
import {OpenAuthModalOptions} from '../../../../models/account/open-auth-modal-options';
import {Router} from '@angular/router';
import {Show} from '../../../../models/program/show';
import {Advertisement} from '../../../../models/resources/advertisement';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {ProgramStatusType} from '../../../../models/lookup/program-status-type';
import {DateUtils} from '../../../../utils/date-utils';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';

@Injectable()
export class ProgramPlayerViewModel extends BaseViewModel {

  isStreamLive$ = new BehaviorSubject<boolean>(null);
  noProgramFound$ = new BehaviorSubject<boolean>(false);
  programContentQuery$ = new BehaviorSubject<ContentQuery>(null);
  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  programId$ = new BehaviorSubject<string>(null);
  isShow$ = new BehaviorSubject<boolean>(false);
  programOrShow$: BehaviorSubject<HydratedProgram | HydratedShow> = new BehaviorSubject<HydratedProgram>(null);
  preRollAd$: BehaviorSubject<Advertisement> = new BehaviorSubject<Advertisement>(null);
  refreshProgram$ = new BehaviorSubject<void>(null);
  fetchProgramError$ = new BehaviorSubject<any>(null);
  fetchProgram = combineLatest([this.refreshProgram$, this.programId$, this.isShow$])
    .pipe(
      debounceTime(200),
      filter(([_, programId, isShow]) => !!programId),
      switchMap(([_, programId, isShow]) => {
        if (isShow) {
          return this.programDomainModel.getShow(programId).pipe(catchError(err => this.handleProgramError(err)));
        } else {
          return this.programDomainModel.getProgram(programId).pipe(catchError(err => this.handleProgramError(err)));
        }
      }),
    ).subscribe(p => {
      this.programOrShow$.next(p);
    }).addTo(this.subscriptions);

  performContentQuery = combineLatest([this.refreshProgram$, this.programContentQuery$])
    .pipe(
      filter(([_, contentQuery]) => !!contentQuery),
      switchMap(([_, contentQuery]) => this.programDomainModel.getSingleHydratedProgramOrShow(contentQuery)
        .pipe(catchError(err => this.handleProgramError(err)))
      ),
      switchMap(p => {
        if (!!p && p instanceof HydratedProgram) {
          return this.programDomainModel.getProgram(p.id);
        } else if (!!p && p instanceof HydratedShow) {
          return this.programDomainModel.getShow(p.id);
        } else {
          return of(null);
        }
      })
    ).subscribe(p => {
      this.noProgramFound$.next(!p);
      this.programOrShow$.next(p);
    }).addTo(this.subscriptions);

  league$: Observable<HydratedLeague> = this.programOrShow$.notNull().pipe(
    filter(p => p.leagueId != null),
    switchMap(p => {
      return this.resourceDomainModel.getHydratedLeague(p?.leagueId);
    }));

  event$: Observable<HydratedEvent> = this.programOrShow$.notNull().pipe(
    filter(p => p.eventId != null),
    switchMap(p => {
      return this.resourceDomainModel.getHydratedEvent(p?.eventId);
    }));

  leagueOrEventIsFree$ = combineLatest([this.league$, this.event$]).pipe(
    switchMap(([l,e]) => {
      if (!!l && l.subscriptionPlanId != null && l.subscriptionPlanId === environment.freePlanId) {
        return of(true);
      }
      if (!!e && e.subscriptionPlanId != null && e.subscriptionPlanId === environment.freePlanId) {
        return of(true);
      }
      return of(false);
    }));

  isUserSubscribed$ = combineLatest([
    this.programOrShow$,
    this.accountDomainModel.subscriberSubscriptions$,
    this.fetchProgramError$
  ]).pipe(
    filter(([program, _, error]) => {
      return !!error || !!program;
    }),
    indicateOnNext(this.loadingOpts, ''),
    map(([program, subscriptions, error]) => {
      if (!!error) {
        return false;
      }
      if (!!program && program.subscriptionPlanId === environment.freePlanId) {
        return true;
      }
      if (!!subscriptions && !!program) {
        const programPlanId = program.subscriptionPlanId;
        return programPlanId === environment.freePlanId
          || subscriptions?.some(s => !s.isSubscriptionInactive() && s.planId === programPlanId);
      }
      return false;
    }));

  isVideoUnavailable$ = combineLatest([this.programOrShow$, this.isUserSubscribed$])
    .pipe(
      filter(([program, isUserSubscribed]) => !!program && !!isUserSubscribed),
      map(([program, _]) => {
        if (program instanceof Program
          && (program.programStatusId === ProgramStatusType.ScheduledId
            || (program.programStatusId === ProgramStatusType.InProgressId
              && DateUtils.dateIsBefore(new Date(), program.startDateUtc) < 0))) {
          return false;
        }
        else if(program instanceof Program &&
          program.programStatusId===ProgramStatusType.CompletedId)
          {
            return true;
          }
        return !program?.playbackStreamUrl;
      })
    );

  resetProgramError = this.programOrShow$.pipe(filter(p => !!p))
    .subscribe(p => this.fetchProgramError$.next(false))
    .addTo(this.subscriptions);

  program$: Observable<Program> = this.programOrShow$.pipe(map(p => p instanceof Program ? p : null));
  countDownSecondInterval$ = interval(1000).pipe(startWith(0));
  showCountDown$ = combineLatest([this.countDownSecondInterval$, this.program$])
    .pipe(map(([c, p]) => !!p && p.startDateUtc > new Date()), distinctUntilChanged());
  countDownSeconds$ = combineLatest([this.countDownSecondInterval$, this.program$.notNull()])
    .pipe(map(([, p]) => this.getStartTimeSeconds(p)));
  countDownMinutes$ = combineLatest([this.countDownSecondInterval$, this.program$.notNull()])
    .pipe(map(([, p]) => this.getStartTimeMinutes(p)));
  countDownHours$ = combineLatest([this.countDownSecondInterval$, this.program$.notNull()])
    .pipe(map(([, p]) => this.getStartTimeHours(p)));
  countDownDays$ = combineLatest([this.countDownSecondInterval$, this.program$.notNull()])
    .pipe(map(([, p]) => this.getStartTimeDays(p)));

  showLiveBadge$ = combineLatest([this.isStreamLive$, this.showCountDown$])
    .pipe(
      map(([isStreamLive, showCountDown]) => !!isStreamLive && !showCountDown),
      debounceTime(500)
    );

  checkProgramNeedsRefresh = this.showCountDown$.pipe(
    filter(showCountDown => !showCountDown),
    take(1)
  ).subscribe(() => {
    if (!this.programOrShow$.value?.playbackStreamUrl) {
      this.refreshProgram$.next();
    }
  }).addTo(this.subscriptions);

  fetchPreRollAd = combineLatest([this.programId$, this.programOrShow$, this.isShow$, this.showCountDown$.pipe(startWith(false))]).pipe(
    filter(([programId, programOrShow, _, showCountDown]) => (!!programId || !!programOrShow) && !showCountDown),
    map(([programId, programOrShow, isShow, _]) => [programId ?? programOrShow?.id, isShow] as [string, boolean]),
    switchMap(([programId, isShow]) => {
      return this.programDomainModel.getAdvertisementForProgramOrShowForPreRoll(programId, isShow);
    })
  ).subscribe(a => this.preRollAd$.next(a)).addTo(this.subscriptions);

  leagueBannerAds$ = this.league$.notNull().pipe(
    map(l => l?.advertisementBanners),
    shareReplay({bufferSize: 1, refCount: true})
  );

  eventBannerAds$ = this.event$.notNull().pipe(
    map(e => e?.advertisementBanners),
    shareReplay({bufferSize: 1, refCount: true})
  );


  constructor(
    private programDomainModel: ProgramDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel,
    private sessionService: SessionService,
    private toastService: ToastService,
    private router: Router,
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.setupBindings();
  }

  setupBindings() {
  }

  submitProgramComment(programComment: ProgramComment) {
    this.programOrShow$.pipe(
      take(1),
      switchMap(p => {
        if (p instanceof Show) {
          return this.programDomainModel.submitShowComment(p.id, programComment);
        } else {
          return this.programDomainModel.submitProgramComment(p.id, programComment);
        }
      })
    ).subscribe(() => {
      this.toastService.publishSuccessMessage($localize`Your feedback has been submitted!`, null);
    }, error => {
      this.toastService.publishError(error);
    });
  }

  private handleProgramError(err) {
    if (err?.code === 401) {
      this.fetchProgramError$.next(err);
      return of(null as HydratedProgram);
    } else {
      this.toastService.publishError(err, $localize`Could Not Load Program`);
      this.returnHome();
      return throwError(err);
    }
  }

  getStartTimeSeconds(program: Program): string {
    const diff = moment.utc(program.startDateUtc).diff(moment(), 'seconds');
    return NumberUtils.zeroPad(diff % 60, 2);
  }

  getStartTimeMinutes(program: Program): string {
    const diff = moment.utc(program.startDateUtc).diff(moment(), 'minutes');
    return NumberUtils.zeroPad(diff % 60, 2);
  }

  getStartTimeHours(program: Program): string {
    const diff = moment.utc(program.startDateUtc).diff(moment(), 'hours');
    return NumberUtils.zeroPad(diff % 24, 2);
  }

  getStartTimeDays(program: Program): string {
    const diff = moment.utc(program.startDateUtc).diff(moment(), 'days');
    return NumberUtils.zeroPad(diff, 2);
  }

  subscribeClicked() {
    if (!this.accountDomainModel.sessionContainer$.getValue()) {
      const programPlanId = this.programOrShow$.getValue().subscriptionPlanId;
      const options = new OpenAuthModalOptions(AuthFlow.SignUp,
        this.router.url, $localize`Sign in to watch this event`, $localize`Sign up to watch this event`, programPlanId);
      this.sessionService.showAuthModal$.next(options);
    } else {
      const programPlanId = this.programOrShow$.getValue().subscriptionPlanId;
      this.sessionService.showEditPlansModalForPlanId$.next(programPlanId);
    }
  }

  returnHome() {
    this.router.navigate(['/home']).then();
  }
}
