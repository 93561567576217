<div class="modal-body sticky">
  <div class="sticky-header-container">
    <div class="modal-header">
      <div class="modal-title" i18n>
        Feedback
      </div>
    </div>
  </div>
  <div class="sticky-body-container">
    <div class="label-sm" i18n>
      Provide HTN HomeTeam Network with feedback about your experience using the app including streaming quality and overall use.
    </div>
    <app-form-group
      class="d-block pt-3"
      *ngIf="formItems?.length > 0"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="formSubmitted($event)">
    </app-form-group>
  </div>
</div>
