import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeaguesComponent} from './leagues/leagues.component';
import {ProfileComponent} from './profile/profile.component';
import {SportsComponent} from './sports/sports.component';
import {UsersComponent} from './users/users.component';
import {VenuesComponent} from './venues/venues.component';
import {AdminRoutingModule} from './admin-routing.module';
import {SharedModule} from '../shared/shared.module';
import {InviteUserModalComponent} from './users/invite-user-modal/invite-user-modal.component';
import {AdminUsersComponent} from './users/admin-users/admin-users.component';
import {AddNewSportModalComponent} from './sports/add-new-sport-modal/add-new-sport-modal.component';
import {VenueDetailsComponent} from './venues/venue-details/venue-details.component';
import {ProgramFeedbackComponent} from './shared/program-feedback/program-feedback.component';
import {EditUserModalComponent} from './users/edit-user-modal/edit-user-modal.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {AdminChangePasswordComponent} from './profile/admin-change-password/admin-change-password.component';
import {LeagueDetailsComponent} from './leagues/league-details/league-details.component';
import {EditVenueStreamModalComponent} from './venues/edit-venue-stream-modal/edit-venue-stream-modal.component';
import {EditTeamModalComponent} from './shared/edit-team-modal/edit-team-modal.component';
import {AddVenueModalComponent} from './shared/add-venue-modal/add-venue-modal.component';
import {EditLeagueComponent} from './leagues/league-details/edit-league/edit-league.component';
import {LeagueProgramsComponent} from './leagues/league-details/league-programs/league-programs.component';
import {LeagueSeasonsComponent} from './leagues/league-details/league-seasons/league-seasons.component';
import {LeagueShowsComponent} from './leagues/league-details/league-shows/league-shows.component';
import {ProgramDetailsComponent} from './shared/program-details/program-details.component';
import {ShowDetailsComponent} from './shared/show-details/show-details.component';
import {
  ProgramDetailsStreamComponent
} from './shared/program-details/program-details-stream/program-details-stream.component';
import {EditVenueComponent} from './venues/venue-details/edit-venue/edit-venue.component';
import {VenueProgramsComponent} from './venues/venue-details/venue-programs/venue-programs.component';
import {VenueStreamsComponent} from './venues/venue-details/venue-streams/venue-streams.component';
import {VenueTeamsComponent} from './venues/venue-details/venue-teams/venue-teams.component';
import {LeagueTeamsComponent} from './leagues/league-details/league-teams/league-teams.component';
import {LeagueVenuesComponent} from './leagues/league-details/league-venues/league-venues.component';
import {AddExistingTeamModalComponent} from './shared/add-existing-team-modal/add-existing-team-modal.component';
import {AdvertisingPartnersComponent} from './advertising-partners/advertising-partners.component';
import {
  EditAdvertisingPartnerModalComponent
} from './advertising-partners/edit-advertising-partner-modal/edit-advertising-partner-modal.component';
import {
  AdvertisingPartnerDetailsComponent
} from './advertising-partners/advertising-partner-details/advertising-partner-details.component';
import {
  AdvertisementDetailsComponent
} from './advertising-partners/advertisement-details/advertisement-details.component';
import {AddLeagueModalComponent} from './shared/add-league-modal/add-league-modal.component';
import {AddRunDatesModalComponent} from './shared/add-run-dates-modal/add-run-dates-modal.component';
import {LeagueBannerAdsComponent} from './leagues/league-details/league-banner-ads/league-banner-ads.component';
import {
  EditBannerAdvertisementModalComponent
} from './shared/edit-banner-ad-modal/edit-banner-advertisement-modal.component';

import {UserAgreementModalComponent} from './shared/user-agreement-modal/user-agreement-modal.component';
import {AdminTeamsComponent} from './admin-teams/admin-teams.component';
import {ProgramUploaderComponent} from './shared/program-uploader/program-uploader.component';
import {EventsComponent} from './events/events.component';
import {EditEventComponent} from './events/events-details/edit-event/edit-event.component';
import {EventBannerAdsComponent} from './events/events-details/event-banner-ads/event-banner-ads.component';
import {EventProgramsComponent} from './events/events-details/event-programs/event-programs.component';
import {EventTeamsComponent} from './events/events-details/event-teams/event-teams.component';
import {EventShowsComponent} from './events/events-details/event-shows/event-shows.component';
import {EventVenuesComponent} from './events/events-details/event-venues/event-venues.component';
import {EventDetailsComponent} from './events/events-details/event-details.component';
import {AddEventModalComponent} from './shared/add-event-modal/add-event-modal.component';
import {ProgramUploaderErrorComponent} from './shared/program-uploader-error/program-uploader-error.component';
import {EditSeasonModalComponent} from './shared/edit-season-modal/edit-season-modal.component';
import {PlansComponent} from './plans/plans.components';
import {PlansTabComponent} from './plans/plans-tab/plans-tab.component';
import {PlansDetailsComponent} from './plans/plans-details/plans-details.component';
import {NgxPopperModule} from 'ngx-popper';
import {CameraSystemsComponent} from './camera-systems/camera-systems.component';
import {
  EditCameraSystemsComponent
} from './camera-systems/camera-systems-details/edit-camera-systems/edit-camera-systems.component';
import {EditAdminUsersComponent} from './users/edit-admin-users/edit-admin-users.component';
import { ProgramUploaderModalComponent } from './shared/program-uploader-modal/program-uploader-modal.component';
import { CameraSystemLandingComponent } from './camera-systems/camera-system-landing/camera-system-landing.component';
import { LiveCameraStreamComponent } from './camera-systems/live-camera-stream/live-camera-stream.component';
import { UpcomingCameraStreamComponent } from './camera-systems/upcoming-camera-stream/upcoming-camera-stream.component';
import { LeagueContributorsComponent } from './leagues/league-details/league-contributors/league-contributors.component';
import { LeagueContributorDetailsComponentComponent } from
'./leagues/league-details/league-contributor-details-component/league-contributor-details-component.component';
import { EventsContributorsComponent } from
'./events/events-details/events-contributors/events-contributors.component';
import { EventsContributorsDetailsComponent } from
'./events/events-details/events-contributors-details/events-contributors-details.component';

import { LeagueStreamsComponent } from './leagues/league-details/league-stream/league-streams.component';
import { EventStreamsComponent } from './events/events-details/event-streams/event-streams.component';

@NgModule({
  declarations: [
    LeaguesComponent,
    LeagueDetailsComponent,
    EventDetailsComponent,
    ProfileComponent,
    AdminChangePasswordComponent,
    SportsComponent,
    UsersComponent,
    VenuesComponent,
    InviteUserModalComponent,
    AdminUsersComponent,
    AddNewSportModalComponent,
    VenueDetailsComponent,
    ProgramFeedbackComponent,
    EditUserModalComponent,
    EditVenueStreamModalComponent,
    EditTeamModalComponent,
    AddVenueModalComponent,
    EditLeagueComponent,
    EditEventComponent,
    LeagueProgramsComponent,
    LeagueSeasonsComponent,
    EditSeasonModalComponent,
    EventProgramsComponent,
    LeagueShowsComponent,
    EventShowsComponent,
    ProgramDetailsComponent,
    ShowDetailsComponent,
    ProgramDetailsStreamComponent,
    EditVenueComponent,
    VenueProgramsComponent,
    VenueStreamsComponent,
    VenueTeamsComponent,
    LeagueTeamsComponent,
    EventTeamsComponent,
    LeagueVenuesComponent,
    EventVenuesComponent,
    LeagueBannerAdsComponent,
    EventBannerAdsComponent,
    EditBannerAdvertisementModalComponent,
    AddExistingTeamModalComponent,
    AdvertisingPartnersComponent,
    EditAdvertisingPartnerModalComponent,
    AdvertisingPartnerDetailsComponent,
    AdvertisementDetailsComponent,
    AddLeagueModalComponent,
    AddEventModalComponent,
    AddRunDatesModalComponent,
    UserAgreementModalComponent,
    AdminTeamsComponent,
    ProgramUploaderComponent,
    EventsComponent,
    ProgramUploaderErrorComponent,
    PlansComponent,
    PlansTabComponent,
    PlansDetailsComponent,
    CameraSystemsComponent,
    EditCameraSystemsComponent,
    EditAdminUsersComponent,
    ProgramUploaderModalComponent,
    CameraSystemLandingComponent,
    LiveCameraStreamComponent,
    UpcomingCameraStreamComponent,
    LeagueStreamsComponent,
    EventStreamsComponent,
    LeagueContributorsComponent,
    LeagueContributorDetailsComponentComponent,
    EventsContributorsComponent,
    EventsContributorsDetailsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    ClipboardModule,
    NgxPopperModule
  ]
})
export class AdminModule {
}
