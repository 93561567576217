<div *ngIf="viewModel.showSignUpBanner$|async" class="banner-container">
  <app-content-container>
    <div class="row">
      <div class="col-12 col-lg-8">
        <ng-container *ngIf="!leagueLandingScreen">
          <div class="title-md mb-4" i18n>Sign up to watch venue streams or premium league content.</div>
          <div class="paragraph-xl" i18n>Choose how you want to use HTN HomeTeam Network with our variety of subscription
            options.
          </div>
        </ng-container>
        <ng-container *ngIf="leagueLandingScreen">
          <div class="title-md mb-4" i18n>
            Access premium broadcasts with league subscriptions.
          </div>
          <div class="paragraph-xl" i18n>
            Choose how you want to use HTN HomeTeam Network with our variety of subscription options.
          </div>
        </ng-container>
        <button class="custom-button preferred-button banner-button"
                (click)="viewModel.signUpClicked(leagueLandingScreen)"
                i18n>Sign Up Now
        </button>
      </div>
    </div>
  </app-content-container>
</div>
