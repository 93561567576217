import {AfterViewInit, Component, EventEmitter, Input, ViewChild} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {SubscriberSubscriptionRequest, SubscriptionPlan} from '../../../../../models/account/dto/subscription-plan';
import {FormOptions} from '../../../../../models/shared/stylesheet/form-options';
import {AuthFlow, EditSubscriptionFlow, GoogleAnalyticsEnum, SubscriberSignUpFlow} from '../../../../../models/account/enum/auth-flow.enum';
import {FormInputItem, FormInputType, FormItemType} from '../../../../../models/shared/stylesheet/form-input-item';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CreateSubscriberPaymentDetails,
  SubscriberPaymentDetails
} from '../../../../../models/account/dto/subscriber-payment-details';
import {PasswordValidatorDirective} from '../../../components/form-group/validators/password-validator.directive';
import {ScreenService} from '../../../../../services/screen-service.service';
import {FormGroupStyling} from '../../../../../models/shared/stylesheet/form-group-styling';
import {SignUpRequest} from '../../../../../models/account/requests/sign-up-request';
import {debounceTime, switchMap} from 'rxjs/operators';
import {AuthModalViewModel} from '../auth-modal-view-model';
import {FormGroupComponent} from '../../../components/form-group/form-group.component';
import {environment} from '../../../../../../environments/environment';
import {CountryType} from '../../../../../models/lookup/country-type';
import {Selectable} from '../../../../../models/protocols/selectable';
import {ResourceDomainModel} from '../../../../../domainModels/resource-domain-model';
import {DropDownItem} from '../../../../../models/shared/stylesheet/drop-down-item';
import {LeagueId} from '../../../../../models/resources/leagueId';
import {RadiobuttonContainerOptions} from '../../../../../models/shared/stylesheet/radiobutton-container-options';
import {Radiobutton} from '../../../../../models/shared/stylesheet/radiobutton';
import {BehaviorSubject} from 'rxjs';
import {SessionContainer} from '../../../../../models/shared/session-container';
import {ToastService} from '../../../../../services/toast-service';
import {EmailVerification} from '../../../../../models/account/requests/email-verification';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics-service';
import { CouponCheck } from 'src/app/models/account/dto/CouponCheck';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent extends BaseComponent implements AfterViewInit {

  @ViewChild('userProfileForm') userProfileForm: FormGroupComponent;
  @ViewChild('paymentDetailsForm') paymentDetailsForm: FormGroupComponent;
  @ViewChild('profileDetailsForm') profileDetailsForm: FormGroupComponent;
  @ViewChild('emailVerificationForm') emailVerificationForm: FormGroupComponent;
  @ViewChild('emailVerificationExpired') emailVerificationExpired: FormGroupComponent;
  @ViewChild('emailVerificationLinkExisted') emailVerificationLinkExisted: FormGroupComponent;
  @ViewChild('emailVerificationUnverified') emailVerificationUnverified: FormGroupComponent;
  @ViewChild('discountCodeForm') discountCodeForm: FormGroupComponent;
  @ViewChild('userAddressForm') userAddressForm: FormGroupComponent;
  @ViewChild('accessCodeForm') accessCodeForm: FormGroupComponent;
  @Input() primaryButtonClicked: EventEmitter<void>;
  @Input() secondaryButtonClicked: EventEmitter<void>;
  @Input() tertiaryButtonClicked: EventEmitter<void>;
  @Input() sessionContainer: BehaviorSubject<SessionContainer>;
  @Input() editingPlans: boolean;

  userProfileFormItems: FormInputItem[] = [];
  userProfileDetailsFormItems: FormInputItem[] = [];
  userProfileDetailsFormOptions = new FormOptions();
  userProfileDetailsFormStyling = new FormGroupStyling();
  userProfileDetailsFormObject: SignUpRequest = new SignUpRequest();
  userProfileFormStyling = new FormGroupStyling();
  userProfileFormOptions = new FormOptions();
  userProfileFormObject: SignUpRequest = new SignUpRequest();
  paymentDetailsFormItems: FormInputItem[] = [];
  paymentDetailsFormStyling = new FormGroupStyling();
  paymentDetailsFormOptions = new FormOptions();
  paymentDetailsFormObject: SubscriberPaymentDetails = new SubscriberPaymentDetails();
  emailVerificationFormItem: FormInputItem[] = [];
  emailVerificationFormStyling = new FormGroupStyling();
  emailVerificationFormOptions = new FormOptions();
  emailVerificationFormObject: EmailVerification = new EmailVerification();
  discountCodeFormItems: FormInputItem[] = [];
  discountCodeFormStyling = new FormGroupStyling();
  discountCodeFormOptions = new FormOptions();
  discountCodeFormObject: DiscountCodeFormObject = new DiscountCodeFormObject();
  updatedFormObject = new EventEmitter<void>();

  accessCodeFormItems: FormInputItem[] = [];
  accessCodeFormStyling = new FormGroupStyling();
  accessCodeFormOptions = new FormOptions();
  accessCodeFormObject: AccesscodeFormObject = new AccesscodeFormObject();

  planRadioButtons: Radiobutton[] = [];
  pricingOptionCheckboxes: Radiobutton[] = [];
  subscriptionPlans: SubscriptionPlan[] = [];
  countries: CountryType[] = [];
  emailVerificationButtonClicked: boolean = false;
  emailVerificationMessage: string = null;
  displayUserExistsMessage: boolean = false;
  passwordErrorMessage: string = null;
  couponcodeCheck= new CouponCheck();

  pickPlanError: string = null;
  profileDetailsError: string = null;
  discountCodeError: string = null;
  radioButtonContainerOptions: RadiobuttonContainerOptions = new RadiobuttonContainerOptions(true);

  constructor(public viewModel: AuthModalViewModel,
              private resourceDomainModel: ResourceDomainModel,
              private toastService: ToastService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private screenService: ScreenService,
              private googleAnalyticsService: GoogleAnalyticsService) {
    super();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  setupBindings() {
    this.viewModel.errorMessage.pipe(debounceTime(100)).subscribe(error => {
      const banner = this.userProfileFormItems.find(f => f.alertBannerId === 'banner');
      if (banner) {
        banner.alertBannerMessage = error;
      }
    }).addTo(this.subscriptions);

    this.viewModel.subscriptionPlans.subscribe(plans => {
      this.subscriptionPlans = plans.filter(p => p.status === 1 && p.id !== environment.freePlanId);
      this.generatePlanRadiobutton();
    }).addTo(this.subscriptions);

    this.primaryButtonClicked.subscribe(() => {
      this.nextButtonClicked();
    }).addTo(this.subscriptions);

    this.secondaryButtonClicked.subscribe(() => {
      this.handleSecondaryButtonClicked();
    }).addTo(this.subscriptions);

    this.tertiaryButtonClicked.subscribe(() => {
      this.viewModel.authFlow = AuthFlow.SignIn;
    }).addTo(this.subscriptions);


    this.viewModel.discountCodeErrorSubject$.subscribe(errorMessage => {
      this.discountCodeError = errorMessage;
    }).addTo(this.subscriptions);

    this.viewModel.countryTypes$.notNull()
      .pipe(debounceTime(100))
      .subscribe((countryTypes) => {
        this.countries = countryTypes;
        this.setupUserProfileFormItems();
        this.setupUserProfileDetailsFormItems();
      }).addTo(this.subscriptions);

    this.generateLeagueDropdownOptions();

    setTimeout(() => {
      if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.CreateProfile) {
        this.activatedRoute.queryParams.subscribe(params => {
          this.viewModel.validateEmailVerificationToken(params.token).subscribe((ev) => {
            this.userProfileFormItems.find(item => item.inputName === 'firstName').setInputFormControlValue(ev.firstName);
            this.userProfileFormItems.find(item => item.inputName === 'lastName').setInputFormControlValue(ev.lastName);
            this.userProfileFormItems.find(item => item.inputName === 'email').setInputFormControlValue(ev.email);
          }, error => {
            if (error.code === 406 || error.code === 410) {
              this.viewModel.subSignUpFlow = SubscriberSignUpFlow.EmailVerificationLinkExpired;
              setTimeout(() => {
                this.emailVerificationFormItem.find(item => item.inputName === 'firstName')
                  .setInputFormControlValue(error.result.firstName);
                this.emailVerificationFormItem.find(item => item.inputName === 'lastName')
                  .setInputFormControlValue(error.result.lastName);
                this.emailVerificationFormItem.find(item => item.inputName === 'email')
                  .setInputFormControlValue(error.result.email);
              }, 1000);
            } else if (error.code === 409) {
              this.viewModel.subSignUpFlow = SubscriberSignUpFlow.EmailVerificationLinkExisted;
              setTimeout(() => {
              this.displayUserExistsMessage = true;
              this.viewModel.hideResendVerificationButton = true;
                this.emailVerificationFormItem.find(item => item.inputName === 'firstName')
                  .setInputFormControlValue(error.result.firstName);
                this.emailVerificationFormItem.find(item => item.inputName === 'lastName')
                  .setInputFormControlValue(error.result.lastName);
                this.emailVerificationFormItem.find(item => item.inputName === 'email')
                  .setInputFormControlValue(error.result.email);
              }, 1000);
            } else if (error.code === 498) {
              this.viewModel.subSignUpFlow = SubscriberSignUpFlow.EmailVerificationInvalidLink;
            }
          });
        });
      }
    }, 1000);

    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.EmailVerificationLinkExpired) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.emailVerificationFormItem.find(item => item.inputName === 'firstName').setInputFormControlValue(params.firstname);
        this.emailVerificationFormItem.find(item => item.inputName === 'lastName').setInputFormControlValue(params.lastname);
        this.emailVerificationFormItem.find(item => item.inputName === 'email').setInputFormControlValue(params.email);
      });
    }
  }

  private handleSecondaryButtonClicked() {
    this.cancel();
  }

  public skipPayment() {
    this.completeProfile(true,true);
  }

  private nextButtonClicked() {
    switch (this.viewModel.subSignUpFlow) {
      case SubscriberSignUpFlow.EmailVerification:
        this.emailVerificationForm.submitForm();
        break;
      case SubscriberSignUpFlow.EmailVerificationUnverified:
        this.emailVerificationUnverified.submitForm();
        break;
      case SubscriberSignUpFlow.EmailVerificationLinkExpired:
        this.emailVerificationExpired.submitForm();
        break;
      case SubscriberSignUpFlow.EmailVerificationInvalidLink:
        this.viewModel.subSignUpFlow = SubscriberSignUpFlow.EmailVerification;
        break;
      case SubscriberSignUpFlow.CreateProfile:
        this.googleAnalyticsService.trackSignUpFlow(
          GoogleAnalyticsEnum.SignupFunnel,
          GoogleAnalyticsEnum.PassWordEntered
        );
        this.userProfileForm.submitForm();
        break;
      case SubscriberSignUpFlow.PickPlanPackage:
      case SubscriberSignUpFlow.PickPlan:
      case SubscriberSignUpFlow.ProfileDetails:
      case SubscriberSignUpFlow.ChooseOrganizationToSupport:
      case SubscriberSignUpFlow.Review:
        this.continueToNextSignUpStep();
        break;
      case SubscriberSignUpFlow.AddPaymentDetails:
        if(this.viewModel.isFromAccount){
          this.googleAnalyticsService.trackSignUpFlow(
            GoogleAnalyticsEnum.PurchaseFunnel,
            GoogleAnalyticsEnum.PaymentInfoSubmitted
          );
        }
        else{
          this.googleAnalyticsService.trackSignUpFlow(
            GoogleAnalyticsEnum.SignupFunnel,
            GoogleAnalyticsEnum.PaymentInfoSubmitted
          );
        }
        this.paymentDetailsForm.submitForm();

        break;
    }
  }

  setupViews() {
    this.setupUserProfileFormItems();
    this.setupUserProfileFormOptions();
    this.setupUserProfileFormStyling();
    this.setupDiscountCodeFormOptions();
    this.setupDiscountCodeFormStyling();
    this.setupDiscountCodeFormItems();
    this.setupPaymentDetailsFormOptions();
    this.setupPaymentDetailsFormStyling();
    this.setupUserProfileDetailsFormItems();
    this.setupUserProfileDetailsFormOptions();
    this.setupUserProfileDetailsFormStyling();
    this.setupEmailVerificationFormItems();
    this.setupEmailVerificationFormOptions();
    this.setupEmailVerificationFormStyling();
    this.setupAccessCodeFormItems();
    this.setupAccessCodeFormOptions();
    this.setupAccessCodeFormStyling();
  }

  setupUserProfileDetailsFormItems() {
    const items: FormInputItem[] = [];

    const city = new FormInputItem();
    city.inputName = 'city';
    city.inputType = FormInputType.Text;
    city.label = $localize`City`;
    city.placeholder = $localize`Enter city or town name`;
    city.bindingProperty = 'address.city';
    city.required = true;
    city.valueChanged.subscribe(([cityEntered]) => {
      this.userProfileDetailsFormObject.address.city = cityEntered;
    });
    items.push(city);

    const country = new FormInputItem();
    country.itemType = FormItemType.Dropdown;
    country.inputName = 'country';
    country.label = $localize`Country`;
    country.placeholder = $localize`Choose a country`;
    country.bindingProperty = 'address.countryId';
    country.dropdownIsObject = true;
    country.dropdownOptions = this.countries;
    country.required = true;
    country.valueChanged.subscribe(([countryId]) => {
      this.setProvinceOptionsForProfileDetails(countryId);
      this.userProfileDetailsFormObject.address.countryId = countryId;
      this.userProfileFormObject.address.countryId = countryId;
    });
    items.push(country);

    const province = new FormInputItem();
    province.itemType = FormItemType.Dropdown;
    province.inputName = 'province';
    province.label = $localize`Province`;
    province.placeholder = $localize`Choose a province`;
    province.bindingProperty = 'address.stateId';
    province.dropdownIsObject = true;
    province.required = true;
    province.dropdownOptions = this.countries?.find(
      c => c.id === CountryType.getCanadaCountryCode())?.states.sort((a, b) => a.name.localeCompare(b.name));
    province.valueChanged.subscribe(([stateId]) => {
      this.userProfileDetailsFormObject.address.stateId = stateId;
    });
    items.push(province);

    const postalCode = new FormInputItem();
    postalCode.inputName = 'postalCode';
    postalCode.inputType = FormInputType.Text;
    postalCode.label = $localize`Postal Code`;
    postalCode.placeholder = $localize`Postal Code`;
    postalCode.bindingProperty = 'address.postalCode';
    postalCode.valueChanged.subscribe(([postalCodeEntered]) => {
      this.userProfileDetailsFormObject.address.postalCode = postalCodeEntered;
    });
    items.push(postalCode);

    this.userProfileDetailsFormItems = items;
  }

  setupDiscountCodeFormItems() {
    const items: FormInputItem[] = [];
    const discountCode = new FormInputItem();
    discountCode.inputName = 'discountCode';
    discountCode.inputType = FormInputType.Text;
    discountCode.label = $localize`Discount Code`;
    discountCode.placeholder = 'Enter discount code';
    discountCode.bindingProperty = 'discountCode';
    discountCode.keyUpValueChanged.subscribe(e => {
      this.viewModel.currentCouponValue = e[0];
    });
    items.push(discountCode);

    this.discountCodeFormItems = items;
  }

  handleDiscountCodeCheck(){
    const discountCode = this.discountCodeFormItems.find(i => i.inputName === 'discountCode');
    this.checkDiscountCode(discountCode.getValue());
    discountCode.clearValue();
    this.viewModel.currentCouponValue = '';
  }

  setupUserProfileFormItems() {
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`Enter your First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    firstName.enabled = false;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Enter your Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    lastName.enabled = false;
    items.push(lastName);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Enter your email`;
    email.bindingProperty = 'email';
    email.required = true;
    email.overrideFullWidth = true;
    email.enabled = false;
    items.push(email);

    const countryOfResidence = new FormInputItem();
    countryOfResidence.inputName = 'countryOfResidence';
    countryOfResidence.itemType = FormItemType.Dropdown;
    countryOfResidence.label = $localize`Country of Residence`;
    countryOfResidence.placeholder = $localize`Select your country of residence`;
    countryOfResidence.bindingProperty = 'address.countryId';
    countryOfResidence.dropdownIsObject = true;
    countryOfResidence.required = true;
    countryOfResidence.dropdownOptions = this.countries;
    countryOfResidence.overrideFullWidth = true;
    items.push(countryOfResidence);

    const password = new FormInputItem();
    password.inputName = 'password';
    password.inputType = FormInputType.Password;
    password.label = $localize`Password`;
    password.placeholder = $localize`Password`;
    password.bindingProperty = 'password';
    password.customValidator = new PasswordValidatorDirective();
    password.required = true;
    password.overrideFullWidth = true;
    password.hideErrorMessage = true;
    // eslint-disable-next-line max-len
    password.hint = $localize`Must be 8 characters long and include at least one number and one uppercase, lowercase, and special character.`;
    items.push(password);

    const reEnterPassword = new FormInputItem();
    reEnterPassword.inputName = 'reEnterpassword';
    reEnterPassword.inputType = FormInputType.Password;
    reEnterPassword.label = $localize`Re-enter Password`;
    reEnterPassword.placeholder = $localize`Re-enter Password`;
    reEnterPassword.bindingProperty = 'confirmPassword';
    reEnterPassword.customValidator = new PasswordValidatorDirective();
    reEnterPassword.required = true;
    reEnterPassword.overrideFullWidth = true;
    reEnterPassword.hideErrorMessage = true;
    reEnterPassword.customClass = 'mb-1';
    reEnterPassword.keyUpValueChanged.pipe(
      debounceTime(300),
    ).subscribe(() => {
      const firstPassword = this.userProfileFormItems.find(f => f.inputName === 'password');
      const secondPassword = this.userProfileFormItems.find(f => f.inputName === 'reEnterpassword');
      if (firstPassword.getValue() !== secondPassword.getValue()) {
        this.passwordErrorMessage = $localize`Passwords do not match`;
      } else {
        this.passwordErrorMessage = $localize`Passwords match`;
      }
    });
    items.push(reEnterPassword);
    this.userProfileFormItems = items;
  }

  setupEmailVerificationFormItems() {
    const items: FormInputItem[] = [];

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`Enter your First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Enter your Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    items.push(lastName);

    const email = new FormInputItem();
    email.inputName = 'email';
    email.inputType = FormInputType.Email;
    email.label = $localize`Email`;
    email.placeholder = $localize`Enter your email`;
    email.bindingProperty = 'email';
    email.required = true;
    email.overrideFullWidth = true;
    items.push(email);
    this.emailVerificationFormItem = items;
  }

  setupPaymentDetailsFormItems(): any {
    const hidePaymentInputs = this.viewModel.isFullDiscount();
    const items: FormInputItem[] = [];

    const banner = new FormInputItem();
    banner.itemType = FormItemType.AlertBanner;
    banner.alertBannerStyle = 'error';
    banner.alertBannerId = 'banner';
    items.push(banner);

    const firstName = new FormInputItem();
    firstName.inputName = 'firstName';
    firstName.inputType = FormInputType.Text;
    firstName.label = $localize`First Name`;
    firstName.placeholder = $localize`First Name`;
    firstName.bindingProperty = 'firstName';
    firstName.required = true;
    items.push(firstName);

    const lastName = new FormInputItem();
    lastName.inputName = 'lastName';
    lastName.inputType = FormInputType.Text;
    lastName.label = $localize`Last Name`;
    lastName.placeholder = $localize`Last Name`;
    lastName.bindingProperty = 'lastName';
    lastName.required = true;
    items.push(lastName);

    const cardNumber = new FormInputItem();
    cardNumber.inputName = 'cardNumber';
    cardNumber.inputType = FormInputType.Text;
    cardNumber.label = $localize`Card Number`;
    cardNumber.placeholder = '0000 0000 0000 0000';
    cardNumber.minLength = 19;
    cardNumber.bindingProperty = 'cardNumber';
    cardNumber.required = true;
    cardNumber.inputMask = FormInputItem.generateCreditCardNumberMask();
    cardNumber.overrideFullWidth = true;
    if (!hidePaymentInputs) {
      items.push(cardNumber);
    }

    const expirationDate = new FormInputItem();
    expirationDate.inputName = 'expirationDate';
    expirationDate.inputType = FormInputType.Text;
    expirationDate.label = $localize`Expiration Date`;
    expirationDate.placeholder = 'MM/YY';
    expirationDate.minLength = 5;
    expirationDate.bindingProperty = 'expiryDate';
    expirationDate.required = true;
    expirationDate.inputMask = FormInputItem.generateCreditCardExpiryMask();
    if (!hidePaymentInputs) {
      items.push(expirationDate);
    }

    const CVVNumber = new FormInputItem();
    CVVNumber.inputName = 'CVVNumber';
    CVVNumber.inputType = FormInputType.NumberInput;
    CVVNumber.label = $localize`Security Code`;
    CVVNumber.placeholder = 'CVV';
    CVVNumber.minLength = 3;
    CVVNumber.bindingProperty = 'cardCVV';
    CVVNumber.required = true;
    CVVNumber.inputMask = FormInputItem.generateCreditCardSecurityCodeMask();
    if (!hidePaymentInputs) {
      items.push(CVVNumber);
    }
    const address1 = new FormInputItem();
    address1.inputName = 'addressLine1';
    address1.inputType = FormInputType.Text;
    address1.label = $localize`Address Line 1`;
    address1.placeholder = $localize`Address`;
    address1.bindingProperty = 'address.addressLine1';
    items.push(address1);

    const address2 = new FormInputItem();
    address2.inputName = 'address2';
    address2.inputType = FormInputType.Text;
    address2.label = $localize`Address Line 2`;
    address2.placeholder = $localize`Apartment, building, floor (optional)`;
    address2.bindingProperty = 'address.addressLine2';
    items.push(address2);

    const city = new FormInputItem();
    city.inputName = 'city';
    city.inputType = FormInputType.Text;
    city.label = $localize`City`;
    city.placeholder = $localize`Enter city or town name`;
    city.bindingProperty = 'address.city';
    city.required = true;
    items.push(city);

    const country = new FormInputItem();
    country.itemType = FormItemType.Dropdown;
    country.inputName = 'country';
    country.label = $localize`Country`;
    country.placeholder = $localize`Choose a country`;
    country.bindingProperty = 'address.countryId';
    country.dropdownIsObject = true;
    country.dropdownOptions = this.countries;
    country.required = true;
    country.valueChanged.subscribe(([countryId]) => {
      this.setProvinceOptions(countryId);
    });
    items.push(country);

    const province = new FormInputItem();
    province.itemType = FormItemType.Dropdown;
    province.inputName = 'province';
    province.label = $localize`Province`;
    province.placeholder = $localize`Choose a province`;
    province.bindingProperty = 'address.stateId';
    province.dropdownIsObject = true;
    province.required = true;
    province.dropdownOptions = this.countries?.find(
      c => c.id === CountryType.getCanadaCountryCode())?.states.sort((a, b) => a.name.localeCompare(b.name));
    items.push(province);

    const postalCode = new FormInputItem();
    postalCode.inputName = 'postalCode';
    postalCode.inputType = FormInputType.Text;
    postalCode.label = $localize`Postal Code`;
    postalCode.bindingProperty = 'address.postalCode';
    items.push(postalCode);

    this.paymentDetailsFormItems = items;
  }

  setupUserProfileFormStyling() {
    this.userProfileFormStyling.numberColumns = 2;
    this.userProfileFormStyling.includePadding = false;
  }

  setupDiscountCodeFormStyling() {
    this.discountCodeFormStyling.includePadding = false;
  }

  setupPaymentDetailsFormStyling() {
    this.paymentDetailsFormStyling.numberColumns = 2;
    this.paymentDetailsFormStyling.includePadding = false;
  }

  setupUserProfileFormOptions() {
    this.userProfileFormOptions.performNonEmptyInitialValidation = false;
    this.userProfileFormOptions.emitInitialValuesAfterSetup = false;
  }

  setupUserProfileDetailsFormOptions() {
    this.userProfileDetailsFormOptions.performNonEmptyInitialValidation = false;
    this.userProfileDetailsFormOptions.emitInitialValuesAfterSetup = false;
  }

  setupUserProfileDetailsFormStyling() {
    this.userProfileDetailsFormStyling.numberColumns = 2;
    this.userProfileDetailsFormStyling.includePadding = false;
  }

  setupPaymentDetailsFormOptions() {
    this.paymentDetailsFormOptions.performNonEmptyInitialValidation = false;
    this.paymentDetailsFormOptions.emitInitialValuesAfterSetup = false;
  }

  setupEmailVerificationFormOptions() {
    this.emailVerificationFormOptions.performNonEmptyInitialValidation = false;
    this.emailVerificationFormOptions.emitInitialValuesAfterSetup = false;
  }

  setupEmailVerificationFormStyling() {
    this.emailVerificationFormStyling.numberColumns = 2;
    this.emailVerificationFormStyling.includePadding = false;
  }

  setupDiscountCodeFormOptions() {
    this.discountCodeFormOptions.performNonEmptyInitialValidation = false;
    this.discountCodeFormOptions.emitInitialValuesAfterSetup = false;
  }
  setupAccessCodeFormItems() {
    const items: FormInputItem[] = [];
    const accessCode = new FormInputItem();
    accessCode.inputName = 'access code';
    accessCode.inputType = FormInputType.Text;
    accessCode.label = $localize``;
    accessCode.placeholder = '';
    accessCode.bindingProperty = 'accesscode';
    accessCode.hint = $localize`Enter valid authorization code to access content`;
    accessCode.inputButtonText = 'Apply';
    accessCode.customClass = 'text-button px-2 ml-2 mb-0';
    accessCode.inputButtonClicked.subscribe(e => {
      this.checkDiscountCode(accessCode.getValue());
      accessCode.clearValue();
    });
    accessCode.inputHasButton = true;
    items.push(accessCode);

    this.accessCodeFormItems = items;
  }
  setupAccessCodeFormOptions() {
    this.accessCodeFormOptions.performNonEmptyInitialValidation = false;
    this.accessCodeFormOptions.emitInitialValuesAfterSetup = false;
  }
  setupAccessCodeFormStyling() {
    this.accessCodeFormStyling.includePadding = false;
  }

  checkDiscountCode(discountCode: string) {
    const selectedSubscriptionPlanCategory = this.viewModel.selectedPlan$?.value?.category;
    if(selectedSubscriptionPlanCategory===3){
      this.couponcodeCheck.isPrivate=true;
    }
    else{
      this.couponcodeCheck.isPrivate=false;
    }
    this.discountCodeError = null;
    this.couponcodeCheck.coupon=discountCode;
    if (!!discountCode) {
      this.viewModel.discountCodeInputToCheck$.next(this.couponcodeCheck);
    }
  }

  verificationDisplayMessage() {
    switch (this.viewModel.subSignUpFlow) {
      case SubscriberSignUpFlow.EmailVerification:
        return $localize`Email verification link sent to the email entered and will be active for 24 hours.
        Login to your email to verify the email and complete the sign up process.`;
      case SubscriberSignUpFlow.EmailVerificationUnverified:
        return $localize`Email verification link already sent to the email "${this.emailVerificationFormObject.email}".
      Resend the Verification link or Login to your email to verify the email.`;
      case SubscriberSignUpFlow.EmailVerificationLinkExisted:
        return $localize`Email already linked to an existing account. Please sign in to your account.`;
      case SubscriberSignUpFlow.EmailVerificationLinkExpired:
        return $localize`Email verification link expired. Resend verification link.`;
      case SubscriberSignUpFlow.EmailVerificationInvalidLink:
        return $localize`Invalid Verification link. Re verify your email.`;
    }
  }

continueToNextSignUpStep() {
    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.EmailVerification) {
      this.googleAnalyticsService.trackSignUpFlow
      (GoogleAnalyticsEnum.SignupFunnel,GoogleAnalyticsEnum.EmailVerification);
      this.viewModel.emailVerificationClicked(this.emailVerificationFormObject);
      this.emailVerificationButtonClicked = true;
      this.verificationDisplayMessage();
      return;
    } else if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.EmailVerificationUnverified) {
      this.viewModel.resendVerificationClicked(this.emailVerificationFormObject);
      this.emailVerificationButtonClicked = true;
      this.verificationDisplayMessage();
      return;
    } else if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.EmailVerificationLinkExpired) {
      this.viewModel.resendVerificationClicked(this.emailVerificationFormObject);
      this.emailVerificationButtonClicked = true;
      this.emailVerificationMessage = $localize`Email verification link sent to the email entered and will be active for 24 hours.`;
      return;
    }
    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.EmailVerificationInvalidLink) {
      this.viewModel.subSignUpFlow = SubscriberSignUpFlow.EmailVerification;
    }

    if (this.viewModel.filterSubscriptionPlanId === environment.freePlanId &&
      this.viewModel.subSignUpFlow === SubscriberSignUpFlow.CreateProfile) {
      this.completeProfile(true);
      return;
    }
    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.PickPlan) {
      const planRequiredError = !this.planRadioButtons.some(p => p.selected);
      if (planRequiredError) {
        this.pickPlanError = $localize`Choose at least one plan from below.`;
        return;
      }
      const selectedPlanRadioButtonsIds: string[] = this.planRadioButtons.filter(p => p.selected)?.map(c => {
        return c.id as string;
      });
      const eventDataMap = new Map<string, string>();
      eventDataMap.set(GoogleAnalyticsEnum.PlanSelected,selectedPlanRadioButtonsIds[0]);
      if(this.viewModel.isFromAccount){
       this.googleAnalyticsService.trackSignUpFlow(
          GoogleAnalyticsEnum.PurchaseFunnel,
          GoogleAnalyticsEnum.PlanSelection,'','','',eventDataMap);
      }
      else{
        this.googleAnalyticsService.trackSignUpFlow(
          GoogleAnalyticsEnum.SignupFunnel,
          GoogleAnalyticsEnum.PlanSelection,'','','',eventDataMap);
      }

  if (selectedPlanRadioButtonsIds.unique()?.length < selectedPlanRadioButtonsIds.length) {
        this.pickPlanError = $localize`More than 1 pricing option has been selected for a plan. Please select a single pricing option.`;
        return;
      }
      if (this.editingPlans && this.sessionContainer.value?.user?.address?.countryId) {
        this.generatePricingOptionCheckBoxes();
      } else {
        if (!this.editingPlans) {
          this.generatePricingOptionCheckBoxes();
        }
      }
    }

    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.PickPlanPackage) {
      const selectedPickPlanPackageIds: string[] = this.pricingOptionCheckboxes.filter(p => p.selected)?.map(c => {
        return c.label as string;
      });
      const selectedPickPlanPackageDurationIds: string[] = this.pricingOptionCheckboxes.filter(p => p.selected)?.map(c => {
        return c.planDuration as string;
      });

      if (selectedPickPlanPackageIds.unique()?.length < selectedPickPlanPackageIds.length) {
        this.pickPlanError = $localize`More than 1 pricing option has been selected for a plan. Please select a single pricing option.`;
        return;
      }
      const eventDataMap = new Map<string, string>();
      eventDataMap.set(GoogleAnalyticsEnum.PackageSelected,selectedPickPlanPackageDurationIds[0]);
      if(this.viewModel.isFromAccount){
       this.googleAnalyticsService.trackSignUpFlow(
          GoogleAnalyticsEnum.PurchaseFunnel,
          GoogleAnalyticsEnum.PackageSelection,'','','',eventDataMap);
      }
      else{
        this.googleAnalyticsService.trackSignUpFlow(
          GoogleAnalyticsEnum.SignupFunnel,
          GoogleAnalyticsEnum.PackageSelection,'','','',eventDataMap);
      }
    }

    if (this.viewModel.editSubscriptionFlow !== null
      && this.viewModel.subSignUpFlow === SubscriberSignUpFlow.Review
      && !!this.viewModel.paymentDetails$.getValue()){
        this.googlePushForReview();
      if (this.viewModel.isEditingDiscount) {
        this.viewModel.addDiscount();
      } else {
        this.viewModel.updateSubscriptions(this.pricingOptionCheckboxes);
      }
      return;
    }

    if (this.viewModel.editSubscriptionFlow !== null && this.viewModel.subSignUpFlow === SubscriberSignUpFlow.Review) {
      this.googlePushForReview();
      if (this.viewModel.isFullDiscount() ) {
        const subscriber = this.sessionContainer?.value?.user;
        if(!subscriber.address?.countryId || !subscriber.address?.stateId){
        subscriber.address.countryId = this.userProfileDetailsFormObject.address.countryId;
        subscriber.address.stateId = this.userProfileDetailsFormObject.address.stateId;
        subscriber.address.city = this.userProfileDetailsFormObject.address.city;
        subscriber.address.postalCode = this.userProfileDetailsFormObject.address.postalCode;
        this.viewModel.domainModel.updateSubscriber(subscriber, null, null).firstNotNull().pipe(
          switchMap(response =>
            this.viewModel.addPaymentDetailsForExistingUser(this.pricingOptionCheckboxes, this.generateCreatePaymentDetailsObject())
          )
        ).subscribe({
          next: () => {
            console.log('Subscriber updated and payment details added successfully');
          },
          error: err => {
            console.log('Error in updating subscriber or adding payment details', err);
            this.viewModel.pushGoogleAnalyticsForPaymentFailure(err.message);
          }
        }).addTo(this.subscriptions);
      }
      else{
          this.viewModel.addPaymentDetailsForExistingUser(this.pricingOptionCheckboxes, this.generateCreatePaymentDetailsObject())
      .subscribe({
        next: () => {
          console.log('Payment details added successfully');
        },
        error: err => {
          console.log('Error in adding payment details', err);
          this.viewModel.pushGoogleAnalyticsForPaymentFailure(err.message);
        }
      }).addTo(this.subscriptions);
      }
        return;
      }
    }

    if (this.viewModel.editSubscriptionFlow !== null
      && this.viewModel.subSignUpFlow === SubscriberSignUpFlow.AddPaymentDetails) {
      const subscriber = this.sessionContainer?.value?.user;
      subscriber.address.countryId = this.paymentDetailsFormObject.address.countryId;
      subscriber.address.stateId = this.paymentDetailsFormObject.address.stateId;
      subscriber.address.city = this.paymentDetailsFormObject.address.city;
      subscriber.address.postalCode = this.paymentDetailsFormObject.address.postalCode;

      this.viewModel.domainModel.updateSubscriber(subscriber, null, null).firstNotNull().pipe(
        switchMap(response =>
          this.viewModel.addPaymentDetailsForExistingUser(this.pricingOptionCheckboxes, this.generateCreatePaymentDetailsObject())
        )
      ).subscribe({
        next: () => {
          console.log('Subscriber updated and payment details added successfully');
        },
        error: err => {
          console.log('Error in updating subscriber or adding payment details', err);
          this.viewModel.pushGoogleAnalyticsForPaymentFailure(err.message);
        }
      }).addTo(this.subscriptions);
      return;
    }

    const selectedPlanIds: string[] = this.planRadioButtons.filter(p => p.selected)?.map(c => {
      return c.id as string;
    });
    if (selectedPlanIds.includes('HomeTeam-Standard') && this.viewModel.subSignUpFlow === SubscriberSignUpFlow.PickPlanPackage) {
      this.viewModel.subSignUpFlow = SubscriberSignUpFlow.ChooseOrganizationToSupport;
      return;
    }

    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.ProfileDetails) {
      if (!this.userProfileDetailsFormObject.address.countryId) {
        this.profileDetailsError = $localize`Please select country of residence`;
        return;
      }
      if (!this.userProfileDetailsFormObject.address.city) {
        this.profileDetailsError = $localize`Please enter city of residence`;
        return;
      }
      if (!this.userProfileDetailsFormObject.address.stateId) {
        this.profileDetailsError = $localize`Please select province (state) of residence`;
        return;
      }
      if (!this.userProfileDetailsFormObject.address.postalCode) {
        this.profileDetailsError = $localize`Please enter your postal code`;
        return;
      }
    }

    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.ProfileDetails) {
      this.generatePricingOptionCheckBoxes();
    }
    if (this.editingPlans && (!this.sessionContainer.value?.user?.address?.countryId
      ||!this.sessionContainer.value?.user?.address?.stateId)) {
      if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.PickPlan) {
        this.viewModel.subSignUpFlow = SubscriberSignUpFlow.ProfileDetails;
        return;
      }
    }
    switch (this.viewModel.subSignUpFlow) {
      case SubscriberSignUpFlow.CreateProfile:
        if (this.userProfileFormObject?.password !== this.userProfileFormObject?.confirmPassword) {
          this.viewModel.errorMessage.next($localize`Passwords do not match`);
        } else {
          this.viewModel.errorMessage.next(null);
          this.viewModel.subSignUpFlow += 1;
        }
        break;
      case SubscriberSignUpFlow.PickPlan:
        this.viewModel.subSignUpFlow += 1;
        break;
      case SubscriberSignUpFlow.ProfileDetails:
        this.viewModel.subSignUpFlow = SubscriberSignUpFlow.PickPlanPackage;
        break;
      case SubscriberSignUpFlow.PickPlanPackage:
        this.viewModel.subSignUpFlow += 1;
        this.updateSubscriber();
        break;
      case SubscriberSignUpFlow.ChooseOrganizationToSupport:
        const eventDataMap = new Map<string, string>();
        eventDataMap.set(GoogleAnalyticsEnum.leagueSelected,this.viewModel.selectedLeague.title);
        if(this.viewModel.isFromAccount){
         this.googleAnalyticsService.trackSignUpFlow(
            GoogleAnalyticsEnum.PurchaseFunnel,
            GoogleAnalyticsEnum.HomeTeamStandardLeagueSelection,'','','',eventDataMap);
        }
        else{
          this.googleAnalyticsService.trackSignUpFlow(
            GoogleAnalyticsEnum.SignupFunnel,
            GoogleAnalyticsEnum.HomeTeamStandardLeagueSelection,'','','',eventDataMap);
        }
        this.viewModel.subSignUpFlow = SubscriberSignUpFlow.Review;
        break;
      case SubscriberSignUpFlow.Review:
        this.googlePushForReview();
        this.viewModel.subSignUpFlow += 1;
        this.setupPaymentDetailsFormItems();
        this.setSelectedCountryDropdown();
        break;
      case SubscriberSignUpFlow.AddPaymentDetails:
        this.completeProfile();
        break;
      case SubscriberSignUpFlow.Completed:
        this.viewModel.signInAfterSignUp();
        break;
    }
  }
  googlePushForReview() {
    if(this.viewModel.isFromAccount){
      this.googleAnalyticsService.trackSignUpFlow(
        GoogleAnalyticsEnum.PurchaseFunnel,
        GoogleAnalyticsEnum.ReviewSubmitted
      );
    }
    else{
      this.googleAnalyticsService.trackSignUpFlow(
        GoogleAnalyticsEnum.SignupFunnel,
        GoogleAnalyticsEnum.ReviewSubmitted
      );
    }
  }


  setSelectedCountryDropdown() {
    const countryInput = this.paymentDetailsFormItems.find(i => i.inputName === 'country');
    setTimeout(() => {
      countryInput.selectDropDown(this.countries.find((country) => {
        return country.id === this.userProfileFormObject.address.countryId;
      }));
    }, 50);
  }

  updateSubscriber() {
    if (this.sessionContainer.value != null &&
      (!this.sessionContainer.value.user.address.countryId ||
        !this.sessionContainer.value.user.address.stateId
      )) {
      const subscriber = this.sessionContainer?.value?.user;
      subscriber.address.countryId = this.userProfileDetailsFormObject?.address?.countryId;
      subscriber.address.stateId = this.userProfileDetailsFormObject?.address?.stateId;
      subscriber.address.city = this.userProfileDetailsFormObject?.address?.city;
      subscriber.address.postalCode = this.userProfileDetailsFormObject?.address?.postalCode;
      this.viewModel.domainModel.updateSubscriber(subscriber, null, null).subscribe((response) => {
      }, error => {
      }).addTo(this.subscriptions);
    }
  }

  backClicked() {
    this.pickPlanError = null;
    this.profileDetailsError = null;
    if (this.viewModel.editSubscriptionFlow === EditSubscriptionFlow.EditPlans
      && this.viewModel.subSignUpFlow === SubscriberSignUpFlow.PickPlan) {
      this.viewModel.dismissModal();
      return;
    }

    if (this.viewModel.editSubscriptionFlow === EditSubscriptionFlow.AddDiscount
      && this.viewModel.subSignUpFlow === SubscriberSignUpFlow.Review) {
      this.viewModel.dismissModal();
      return;
    }

    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.ProfileDetails) {
      this.viewModel.subSignUpFlow = SubscriberSignUpFlow.PickPlan;
      return;
    }

    if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.CreateProfile) {
      this.viewModel.dismissModal();
    } else if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.ChooseOrganizationToSupport) {
      this.viewModel.subSignUpFlow = SubscriberSignUpFlow.PickPlanPackage;
    } else if (this.viewModel.subSignUpFlow === SubscriberSignUpFlow.Review) {
      const selectedPlanIds: string[] = this.planRadioButtons.filter(p => p.selected)?.map(c => {
        return c.id as string;
      });
      if (selectedPlanIds.includes('HomeTeam-Standard')) {
        this.viewModel.subSignUpFlow = SubscriberSignUpFlow.ChooseOrganizationToSupport;
      } else {
        this.viewModel.subSignUpFlow = SubscriberSignUpFlow.PickPlanPackage;
      }
    } else {
      this.viewModel.subSignUpFlow -= 1;
    }
  }

  editClicked() {
    this.viewModel.subSignUpFlow = SubscriberSignUpFlow.PickPlanPackage;
  }

  displayPlanPrice(subDescription): string {
    const selectedSubscriptionPlanId = this.viewModel.selectedPlan$.value.id;
    const selectedSubscriptionPlanCategory = this.viewModel.selectedPlan$.value.category;
    if (typeof selectedSubscriptionPlanId === 'string' && selectedSubscriptionPlanCategory === 2) {
      const subDescriptionSplittedFirstPart = subDescription.split('/');
      const eventPrice = subDescriptionSplittedFirstPart[0];
      return eventPrice;
    }
    if (subDescription) {
      const subDescriptionSplittedFirstPart = subDescription.split(' ');
      const subDescriptionSplittedSecondPart = subDescription.split('\n');
      const firstPart = subDescriptionSplittedFirstPart[0];
      const secondPart = subDescriptionSplittedSecondPart[1];
      return firstPart + secondPart;
    }
    return '';
  }


  generatePricingOptionCheckBoxes() {
    const radiobuttons: Radiobutton[] = [];
    let countryIsCanada = null;
    if (this.editingPlans) {
      if (this.userProfileDetailsFormObject.address.countryId) {
        countryIsCanada = this.userProfileDetailsFormObject.address.countryId === 1;
      } else {
        countryIsCanada = this.sessionContainer.value?.user?.address?.countryId === 1;
      }
    } else {
      countryIsCanada = this.userProfileFormObject.address.countryId === 1;
    }
    this.subscriptionPlans.forEach((subPlan, index) => {
      // Bellow status.toString() === '1' overrides the pricing options status enum type.
      // this is done due to backend type change from string to number.
      const activePricingOptions = subPlan.pricingOptions.filter(pp => pp.status.toString() === '1' && pp.id !== environment.freePlanId);
      const validPricingOptions = activePricingOptions.filter((p) => {
        if (countryIsCanada) {
          if (p.getPriceDescription().includes('CAD')) {
            return true;
          }
        } else {
          if (p.getPriceDescription().includes('USD')) {
            return true;
          }
        }
      });
      const pricingOptionsToUse = validPricingOptions.length > 0 ? validPricingOptions : activePricingOptions;
      pricingOptionsToUse.forEach(pricingOption => {
        const subscriptionPlanCheckbox = this.planRadioButtons?.find(p => p.id === subPlan.id);
        if (!!subscriptionPlanCheckbox && subscriptionPlanCheckbox.selected && !subscriptionPlanCheckbox.disabled) {
          radiobuttons.push(new Radiobutton(
            this.displayPlanPrice(pricingOption.getPriceDescription()),
            false,
            false,
            subPlan.description,
            pricingOption.id,
            pricingOption.getPriceDescription(),
            subPlan.id,
            pricingOption.periodUnit,
            subPlan.category,
          ));
        }
      });
      radiobuttons.sort((a, b) => a.id.toString().localeCompare(b.id.toString()));

      this.pricingOptionCheckboxes = radiobuttons;
      this.selectedPricingOptionsChanged();
    });
  }


  generatePlanRadiobutton() {
    const radiobutton: Radiobutton[] = [];
    this.subscriptionPlans.forEach((subPlan, index) => {
      if (subPlan.status === 1 && subPlan.id !== environment.freePlanId) {
        radiobutton.push(new Radiobutton(
          subPlan.externalName,
          false,
          false,
          subPlan.description,
          subPlan.id,
          null,
          subPlan.id));
      }
    });

    radiobutton.sort((a, b) => {
      if (a.id === 'HomeTeam-Standard') {
        return -1;
      }
      return a.id.toString().localeCompare(b.id.toString());
    });

    let preselectedChecked = false;
    radiobutton.forEach(pc => {
      if (this.viewModel.existingSubscriberSubscriptions?.findIndex(s => s.planId === pc.id) >= 0) {
        pc.selected = true;
        pc.disabled = true; // existing subscription plans are disabled
      } else if (pc.altId === this.viewModel.preselectPlanId && !preselectedChecked) {
        pc.selected = true;
        preselectedChecked = true;
      }
    });
    this.planRadioButtons = radiobutton.filter(cb => {
      return !this.viewModel.filterSubscriptionPlanId || cb.altId === this.viewModel.filterSubscriptionPlanId;
    });
  }

  completeProfile(skipPlanAndPayment: boolean = false,skipPaymentForGooglePush: boolean=false) {
    const coupons = this.viewModel.couponsToApply$.getValue();
    const invoiceCoupons = this.viewModel.getInvoiceCoupons(coupons);
    const plans = skipPlanAndPayment ? [] : this.pricingOptionCheckboxes.filter(p => p.selected).map(p => {
      const planCoupons = [...invoiceCoupons, ...this.viewModel.getAppliedCouponsForPlan(p.id as string, coupons)];
      return new SubscriberSubscriptionRequest(p.id as string, planCoupons.map(c => c.id));
    });
    const hidePaymentInputs = this.viewModel.isFullDiscount();
    let paymentDetails = new CreateSubscriberPaymentDetails();
    paymentDetails = skipPlanAndPayment || hidePaymentInputs ? paymentDetails : this.generateCreatePaymentDetailsObject();
    if (!skipPlanAndPayment) {
      this.userProfileFormObject.address = this.paymentDetailsFormObject.address;
    }
    this.userProfileFormObject.address.removeNullValues();
    if (this.viewModel.selectedLeague.value !== -1) {
      this.userProfileFormObject.favouriteLeagues = [new LeagueId(this.viewModel.selectedLeague.value)];
    }
    this.viewModel.createSubscriber(this.userProfileFormObject, paymentDetails,
      plans, skipPlanAndPayment,skipPaymentForGooglePush,this.pricingOptionCheckboxes.filter(p => p.selected));
  }

  generateCreatePaymentDetailsObject(): CreateSubscriberPaymentDetails {
    const paymentDetails = new CreateSubscriberPaymentDetails();
    paymentDetails.lastName = this.paymentDetailsFormObject.lastName;
    paymentDetails.firstName = this.paymentDetailsFormObject.firstName;
    paymentDetails.cardCVV = this.paymentDetailsFormObject.cardCVV;
    paymentDetails.cardNumber = this.paymentDetailsFormObject.cardNumber?.replace(/\s+/g, '');
    const splitExpiry = this.paymentDetailsFormObject.expiryDate?.split('/');
    paymentDetails.cardExpiryYear = Number(splitExpiry[1]);
    paymentDetails.cardExpiryMonth = Number(splitExpiry[0]);
    return paymentDetails;
  }

  generateLeagueDropdownOptions() {
    this.resourceDomainModel.getLeagues(true).subscribe(leagues => {
      leagues.filter((l) => {
        return l.subscriptionPlanId === 'HomeTeam-Standard';
      }).forEach(l => {
        this.viewModel.leagues.push(new DropDownItem(l.name, l.id));
      });
    });
  }

  selectedLeagueChanged(l: Selectable) {
    this.viewModel.selectedLeague = l as DropDownItem;
  }

  selectedPricingOptionsChanged() {
    const selectedPricingOptions = this.subscriptionPlans?.flatMap(plan => plan.pricingOptions).filter(p => {
      return !!this.pricingOptionCheckboxes?.find(c => c.selected && p?.id === c.id);
    });
    this.viewModel.selectedPlanPricingOptions$.next(selectedPricingOptions);
  }

  private setProvinceOptions(countryId: number) {
    const province = this.paymentDetailsFormItems.find(f => f.inputName === 'province');
    province.dropdownOptions = this.countries?.find(
      c => c.id === countryId)?.states.sort((a, b) => a.name.localeCompare(b.name));
  }

  private setProvinceOptionsForProfileDetails(countryId: number) {
    const province = this.userProfileDetailsFormItems.find(f => f.inputName === 'province');
    province.dropdownOptions = this.countries?.find(
      c => c.id === countryId)?.states.sort((a, b) => a.name.localeCompare(b.name));
  }

  onRadioButtonGroupChange(event: any) {
    if (this.radioButtonContainerOptions.touched === true) {
      this.radioButtonContainerOptions.touched = false;
    }
    const planId = this.planRadioButtons.find(p => p.selected)?.id;
    const selectedPlan = this.viewModel.subscriptionPlans.value.find(p => p.id === planId);
    this.viewModel.selectedPlan$.next(selectedPlan);
  }

  cancel() {
    this.viewModel.dismissModal();
  }

  paymentDurationDescription() {
    const selectedSubscriptionPlanId = this.viewModel.selectedPlan$?.value?.id;
    const selectedSubscriptionPlanCategory = this.viewModel.selectedPlan$?.value?.category;
    const selectedSubscriptionPlanDuration = this.pricingOptionCheckboxes?.find(p => p.selected)?.planDuration;
    const dayPricingOption = selectedSubscriptionPlanDuration?.match(/day/g);
    if(selectedSubscriptionPlanCategory ===3){
      return ``;
    }
    if (typeof selectedSubscriptionPlanId === 'string' && selectedSubscriptionPlanCategory === 2) {
      const selectedPricingRadioButton = this.pricingOptionCheckboxes?.find(p => p.selected);

      if (dayPricingOption) {
        return $localize`Auto expires after 24 hours`;
      } else if(selectedPricingRadioButton?.planDuration === 'week'){
        return $localize`Access all content for this event for a week `;
      }
      else if(selectedPricingRadioButton?.planDuration === 'year'){
        return $localize`Access all content for this event`;
    }
  }
    const selectedPricingOptionRadioButton = this.pricingOptionCheckboxes?.find(p => p.selected);

    if (selectedPricingOptionRadioButton?.planDuration === 'day') {
      return $localize`Auto expires after 24 hours`;
    }
    if (selectedPricingOptionRadioButton?.planDuration === 'month') {
      // eslint-disable-next-line max-len
      return $localize`Watch Live and On Demand\nBilled monthly - Auto renews\nCancel anytime`;
    }
    if (selectedSubscriptionPlanId === 'HomeTeam-Standard') {
      return $localize`Watch Live and On Demand\nBilled Annually - Auto renews\nCancel anytime`;
    }
    if (selectedPricingOptionRadioButton?.planDuration === 'year') {
      // eslint-disable-next-line max-len
      return $localize`Watch Live and On Demand\nAuto expires - Does not Renew`;
    }
  }

  mostPopularText(): string {
    const selectedSubscriptionPlanId = this.viewModel.selectedPlan$?.value?.id;
    const selectedPricingPlan = this.pricingOptionCheckboxes?.find(p => p.selected);
    const selectedSubscriptionPlanCategory = this.viewModel.selectedPlan$?.value?.category;
    if(selectedSubscriptionPlanCategory === 3){
      return $localize`Access by invite only\nAuthorization Code required to access content`;
    }
    if (selectedPricingPlan?.planDuration === 'year' && !selectedSubscriptionPlanId.toLowerCase().startsWith(`event_`)) {
      return $localize`Most Popular\n`;
    }
  }

  displayLabel() {
    const selectedPlanLabel = this.planRadioButtons?.find(p => p.selected)?.label;
    if (selectedPlanLabel === selectedPlanLabel) {
      return selectedPlanLabel;
    }
  }
}

export class DiscountCodeFormObject {
  discountCode: string = '';
}

export class AccesscodeFormObject{
  accessCode: string= '' ;
}




