
  <div class="mt-3 position-relative">
    <app-loading
      [hidden]="!viewModel.loadingOpts?.isLoading"
      [options]="viewModel.loadingOpts">
    </app-loading>
    <app-data-table
      [tableData]="userDataProvider"
      [tableFilter]="datatableFilter"
      [updateTableData]="updateTableData"
      [filterApplied]="tableFilterChanged"
      (rowClicked)="rowClicked($event)"
       (ctaButtonClicked)= "addNewContributor()"
      [tableOptions]="datatableOptions">
  </app-data-table>
  </div>