import {Component} from '@angular/core';
import {LeagueDetailsViewModel} from './league-details-view-model';
import {BaseComponent} from '../../../../models/base/base-component';
import {TabBarItem} from '../../../../models/shared/stylesheet/tab-bar-item';
import {EditLeagueComponent} from './edit-league/edit-league.component';
import {LeagueProgramsComponent} from './league-programs/league-programs.component';
import {LeagueSeasonsComponent} from './league-seasons/league-seasons.component';
import {LeagueShowsComponent} from './league-shows/league-shows.component';
import {LeagueTeamsComponent} from './league-teams/league-teams.component';
import {LeagueVenuesComponent} from './league-venues/league-venues.component';
import {ActivationEnd, Router} from '@angular/router';
import {LeagueBannerAdsComponent} from './league-banner-ads/league-banner-ads.component';
import { LeagueStreamsComponent } from './league-stream/league-streams.component';
import { LeagueContributorsComponent } from './league-contributors/league-contributors.component';

@Component({
  selector: 'app-league-details',
  templateUrl: './league-details.component.html',
  styleUrls: ['./league-details.component.scss'],
  providers: [LeagueDetailsViewModel],
})
export class LeagueDetailsComponent extends BaseComponent {

  tabs: TabBarItem[] = [];

  constructor(
    public viewModel: LeagueDetailsViewModel,
    private router: Router,
  ) {
    super();
  }

  setupBindings() {
    this.router.events.subscribe(ev => {
      if (ev instanceof ActivationEnd && Object.is(ev?.snapshot?.component, LeagueDetailsComponent)) {
        this.viewModel.refreshLeagueProgramsAndShows$.next();
      }
    });
  }

  setupViews() {
    this.setupTabBar();
  }

  setupTabBar() {
    this.tabs = [];
    let id = 1;
    const details = new TabBarItem(EditLeagueComponent, id++, $localize`Details`, '');
    details.active = true;
    const teams = new TabBarItem(LeagueTeamsComponent, id++, $localize`Teams`, '');
    const venues = new TabBarItem(LeagueVenuesComponent, id++, $localize`Venues`, '');
    const seasons = new TabBarItem(LeagueSeasonsComponent, id++, $localize`Seasons`, '');
    const programs = new TabBarItem(LeagueProgramsComponent, id++, $localize`Programs`, '');
    const shows = new TabBarItem(LeagueShowsComponent, id++, $localize`Shows`, '');
    const ads = new TabBarItem(LeagueBannerAdsComponent, id++, $localize`Banner Ads`, '');
    const streams = new TabBarItem(LeagueStreamsComponent, id++, $localize`Streams`, '');
    const contributor=new TabBarItem(LeagueContributorsComponent,id++,$localize`Contributors`,'');
    this.tabs.push(...[details, teams, venues, seasons, programs, shows, ads,contributor,streams]);
  }
}
