import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy, Output, SecurityContext,
  SimpleChanges
} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';
import '../../../../utils/observable.extensions';
import '../../../../utils/subscription.extensions';
import {DomSanitizer} from '@angular/platform-browser';
import videojs from 'video.js';
import {Program} from '../../../../models/program/program';
import {Show} from '../../../../models/program/show';
import {Advertisement} from '../../../../models/resources/advertisement';
import {IvsVideoPlayerViewModel} from './ivs-video-player-view-model';
import { ProgramStatusType } from 'src/app/models/lookup/program-status-type';
import * as moment from 'moment';
import { DateUtils } from 'src/app/utils/date-utils';
import { Router } from '@angular/router';


@Component({
  selector: 'app-ivs-video-player',
  templateUrl: './ivs-video-player.component.html',
  styleUrls: ['./ivs-video-player.component.scss'],
  providers: [IvsVideoPlayerViewModel]
})
export class IvsVideoPlayerComponent extends BaseComponent implements OnDestroy, OnChanges {
  @Input() program: Program|Show;
  @Input() advertisement: Advertisement;
  @Input() isLive$ = new BehaviorSubject<boolean>(null);
  @Input() playVideo: EventEmitter<boolean>;
  @Output() feedbackButtonClicked = new EventEmitter<void>();
  @Input() homeTeamScore$ = new BehaviorSubject<number>(0);
  @Input() visitingTeamScore$ = new BehaviorSubject<number>(0);
  @Input() isStartedScoring$ = new BehaviorSubject<boolean>(true);
  player: videojs.Player;
  homeTeamName: string;
  visitingTeamName: string;

  constructor(
    private sanitizer: DomSanitizer,
    public viewModel: IvsVideoPlayerViewModel,
    public router: Router,
  ) {
    super();
  }

  ivsPlayer(): any {
    return (this.player as any).getIVSPlayer();
  }

  setupViews() {
    registerIVSTech(videojs);
    registerIVSQualityPlugin(videojs);
    // Initialize player
    this.player = videojs('video-js-target', {
      fluid: true,
      autoplay: true,
      muted: false,
      liveui: true,
      techOrder: ['AmazonIVS'],
      html5: {
        hls: {
          overrideNative: true
        }
      },
      controlBar: {durationDisplay: true, captionsButton: false, subtitlesButton: false, subsCapsButton: false}
    }, () => {
      (this.player as any).enableIVSQualityPlugin();
      this.player.one('loadedmetadata', () => {
        const qualities = (this.player as any).getIVSPlayer().getQualities();
        //set the quality to 720P and allow user to select higher quality level
        const quality720p = qualities.find(q => q.height === 720);
        const isProgram=this.findProgramOrShow(this.program);
        const programDetails=this.program as Program;
        if (quality720p && (!programDetails.isPixellotSystem || programDetails.productionTypeId===1) && isProgram) {
            (this.player as any).getIVSPlayer().setQuality(quality720p);
        }
    });
      this.viewModel.playerReady.next(true);
      this.addFeedBackButton();
      // (this.player.liveTracker.options_ as any).trackingThreshold = 0;
    });
  }

  setupBindings() {
    combineLatest([
      this.viewModel.playerReady,
      this.viewModel.playerSrcUrl,
      this.viewModel.preRollAdSrcUrl,
      this.viewModel.preRollAdCompleted
      ]).pipe(filter(([ready, playerSrcUrl, preRollAdSrcUrl, _]) => !!ready && (!!playerSrcUrl || !!preRollAdSrcUrl)))
      .subscribe(([ready, playerSrcUrl, preRollAdSrcUrl, preRollAdCompleted]) => {
        if ((preRollAdCompleted || !preRollAdSrcUrl) && !!playerSrcUrl) {
          this.viewModel.playEventTracked = false;
          this.player.src(playerSrcUrl);
          this.viewModel.playingPreRollAd = false;
          setTimeout(() => {
            this.player.pause();
          });
        } else if (!preRollAdCompleted && !!preRollAdSrcUrl) {
          this.player.src(preRollAdSrcUrl);
          this.viewModel.playingPreRollAd = true;
          setTimeout(() => {
            this.player.muted(false);
            this.player.play().then();
          });
        }
      }).addTo(this.subscriptions);

    combineLatest([this.viewModel.playerReady, this.viewModel.playerPosterSrc])
      .pipe(filter(([ready, playerPosterSrc]) => !!ready))
      .subscribe(([ready, playerPosterSrc]) => {
        this.player.poster(playerPosterSrc);
      }).addTo(this.subscriptions);

    const PlayerEvents = (this.player as any).getIVSEvents();
    this.ivsPlayer().addEventListener(PlayerEvents.PlayerEventType.DURATION_CHANGED, () => {
      const duration = this.ivsPlayer().getDuration();
      this.isLive$.next(duration === Infinity);
    });

    this.ivsPlayer().addEventListener(PlayerEvents.PlayerState.PLAYING, () => {
      if (!this.viewModel.playEventTracked && !this.viewModel.playingPreRollAd) {
        if(this.program instanceof Program){
          const streamType= this.program.programStatusId===ProgramStatusType.InProgressId
          ? 'live' : 'past_program';
          this.viewModel.trackVideoEvent(this.program, 'play',streamType);
        }
        else{
          this.viewModel.trackVideoEvent(this.program, 'play','past_program');
        }


      }
    });

    this.ivsPlayer().addEventListener(PlayerEvents.PlayerState.ENDED, () => {
      if (!!this.viewModel.playingPreRollAd) {
        this.viewModel.preRollAdCompleted.next(true);
      }
    });

    this.playVideo?.subscribe(startFromBeginning => {
      if (startFromBeginning) {
        this.ivsPlayer()?.seekTo(0);
      }
      this.player?.play().then();
    }).addTo(this.subscriptions);

    this.player.on('fullscreenchange', () => {
      const isFullscreen = this.player.isFullscreen();
      const overlay = document.querySelector('.score-bug-overlay') as HTMLElement;
      const videoContainer = this.player.el();
      if (overlay) {
        if (isFullscreen) {
          overlay.classList.add('fullscreen-overlay');
          if (videoContainer) {
            videoContainer.appendChild(overlay);
          }
        } else {
          overlay.classList.remove('fullscreen-overlay');
          const originalContainer = document.querySelector('.ivs-video-player-container');
          if (originalContainer) {
            originalContainer.appendChild(overlay);
          }
        }
      }
    });
    this.player.on('enterpictureinpicture', () => {
      const overlay = document.querySelector('.score-bug-overlay') as HTMLElement;
      const videoContainer = this.player.el();
      if (overlay && videoContainer) {
        overlay.classList.add('d-none');
        videoContainer.appendChild(overlay);
      }
    });
    this.player.on('leavepictureinpicture', () => {
      const overlay = document.querySelector('.score-bug-overlay') as HTMLElement;
      const originalContainer = document.querySelector('.ivs-video-player-container');
      if (overlay) {
        overlay.classList.remove('d-none');
        if (originalContainer) {
          originalContainer.appendChild(overlay);
        }
      }
    });
    if (this.program?.endDateTimeUtc && this.program?.nextProgramId) {
      this.scheduleGameCheck(this.program.endDateTimeUtc,this.program.nextProgramId,this.program.id);
    }
    
  }

  scheduleGameCheck(endDateTimeUtc: Date, nextProgramId: string, id: string) {
    const delay= DateUtils.getDelay(endDateTimeUtc)-60000;

    if(delay>0){
      setTimeout(()=>{
        this.viewModel.getProgramEndTime(id).subscribe((pdt)=>{
          if(endDateTimeUtc!==pdt.endTimeUtc){
            this.scheduleGameCheck(pdt.endTimeUtc,nextProgramId,id);
          }
          else{
            setTimeout(()=>{
              this.router.navigate([`/home/${nextProgramId}`]).then();
            },60000);
          }
        });
      },delay);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.player.dispose();
    this.viewModel.stopTracking();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.advertisement && this.advertisement?.amazonPlaybackStreamUrl) {
      this.viewModel.preRollAdSrcUrl.next(this.advertisement.amazonPlaybackStreamUrl);
      this.viewModel.advertisementId = this.advertisement.id;
    }

    if (changes.program) {
      if (!!this.program?.playbackStreamUrl) {
        this.viewModel.playerSrcUrl.next(this.program.playbackStreamUrl);
      }
      if (!!this.program?.imgSrc$) {
        this.program?.imgSrc$.subscribe(imgSrc => {
          const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, imgSrc);
          this.viewModel.playerPosterSrc.next(sanitizedUrl);
        }).addTo(this.subscriptions);
      }
      if(this.program instanceof Program){
        this.homeTeamName = this.program.homeTeam;
        this.visitingTeamName = this.program.visitingTeam;
      }
  }
  }

  addFeedBackButton() {
    const feedbackButton = this.player.controlBar.addChild('button');
    const feedbackButtonDom = feedbackButton.el();
    feedbackButtonDom.innerHTML = '';
    feedbackButton.addClass('feedback-button');
    (feedbackButtonDom as any).title = $localize`Feedback`;
    (feedbackButtonDom as any).onclick = () => {
      if (this.player.isFullscreen()) {
        this.player.exitFullscreen();
      }
      this.feedbackButtonClicked.emit();
    };
  }
   findProgramOrShow(program: Program | Show): boolean {
    const obj=program as Program;
    if(obj.programStatusId){
      return true;
    }
    else{
      return false;
    }
  }
}


