<div class="modal-body sticky">
  <div class="sticky-header-container auth-modal-header"
       [hidden]="viewModel.authFlow === authFlowType.SignUp && viewModel.subSignUpFlow === subSignUpFlowType.Completed">
    <div (click)="backClicked()"
         *ngIf="viewModel.backButtonVisibleForAuthFlow()"
         class="auth-modal-back-button">
      <img class="icon-size-4" [src]="'assets/icons/light/outline/arrow-left.svg'" alt="">
      <div class="N27-regular-400 f20px ml-2">
        Back
      </div>
    </div>
    <div class="auth-modal-title">
      {{viewModel.getAuthFlowTitle()}}
    </div>
  </div>
  <div class="sticky-body-container auth-modal-body position-relative"
       [class.sign-up-complete-container]="viewModel.authFlow === authFlowType.SignUp && viewModel.subSignUpFlow === subSignUpFlowType.Completed">
    <app-loading class="mt-5 min-height-15"
                 [hidden]="!viewModel.loadingOptions.isLoading"
                 [options]="viewModel.loadingOptions">
    </app-loading>
    <app-sign-in-form
      [primaryButtonClicked]="primaryButtonClicked"
      [secondaryButtonClicked]="secondaryButtonClicked"
      [tertiaryButtonClicked]="tertiaryButtonClicked"
      *ngIf="viewModel.authFlow === authFlowType.SignIn">
    </app-sign-in-form>
    <app-sign-up-form
      [editingPlans]="editingPlans"
      [sessionContainer]="viewModel.sessionContainer$"
      #signUpFormComponent
      [hidden]="viewModel.loadingOptions.isLoading"
      [primaryButtonClicked]="primaryButtonClicked"
      [secondaryButtonClicked]="secondaryButtonClicked"
      [tertiaryButtonClicked]="tertiaryButtonClicked"
      *ngIf="viewModel.authFlow === authFlowType.SignUp">
    </app-sign-up-form>
    <app-forgot-password-form
      [primaryButtonClicked]="primaryButtonClicked"
      [secondaryButtonClicked]="secondaryButtonClicked"
      [tertiaryButtonClicked]="tertiaryButtonClicked"
      *ngIf="viewModel.authFlow === authFlowType.ForgotPassword">
    </app-forgot-password-form>
    <app-reset-password-form
      [primaryButtonClicked]="primaryButtonClicked"
      [secondaryButtonClicked]="secondaryButtonClicked"
      *ngIf="viewModel.authFlow === authFlowType.ResetPassword">
    </app-reset-password-form>
    <app-new-password-form
      [primaryButtonClicked]="primaryButtonClicked"
      [secondaryButtonClicked]="secondaryButtonClicked"
      *ngIf="viewModel.authFlow === authFlowType.SetNewPassword">
    </app-new-password-form>
  </div>
  <div class="sticky-footer-container modal-footer-flex-end pt-4"
       [hidden]="viewModel.loadingOptions.isLoading || (viewModel.authFlow === authFlowType.SignUp && viewModel.subSignUpFlow === subSignUpFlowType.Completed)">
    <button class="custom-button text-button mr-auto px-0"
            (click)="tertiaryButtonClicked.emit()"
            [hidden]="!viewModel.getTertiaryButtonText() || (viewModel.reLoginErrorMessage|async)">
      {{viewModel.getTertiaryButtonText()}}
    </button>
    <button (click)="secondaryButtonClicked.emit()"
            [hidden]="!viewModel.getSecondaryButtonText() || (viewModel.reLoginErrorMessage|async)"
            [ngClass]="viewModel.getSecondaryButtonClass()"
            class="custom-button neutral-button">
      {{viewModel.getSecondaryButtonText()}}
    </button>

    <button class="custom-button neutral-button"
            *ngIf="viewModel.authFlow === authFlowType.SignUp && viewModel.subSignUpFlow === subSignUpFlowType.PickPlan && viewModel.editSubscriptionFlow === null "
            (click)="signUpFormComponent.skipPayment()">
      Skip for now
    </button>
    <button
            (click)="primaryButtonClicked.emit()"
            [disabled]="viewModel.isPrimaryButtonDisabled()"
            [hidden]="!viewModel.showVerifyEmailButton || (viewModel.reLoginErrorMessage | async) ||
            this.viewModel.hideResendVerificationButton === true"
            class="custom-button ml-3 preferred-button"
            [class.disabled]="viewModel.isPrimaryButtonDisabled()">
      {{viewModel.getPrimaryButtonText()}}
    </button>
  </div>
</div>
