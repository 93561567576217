<app-detail-title
  [hrCustomClass]="'d-none'"
  i18n>
  Contributors
</app-detail-title>

<app-content-container [narrow]="true">
    <div *ngIf="formItems?.length > 0 && !!formObject" class="py-4 h-100 position-relative min-height-20">
      <div *ngIf="!(formObject.itemChanged)">
        <app-detail-back-button [backRoute]="'..'" class="d-block mt-4">
          <ng-container i18n>Add a New Contributor</ng-container>
        </app-detail-back-button>
      </div>
        <div *ngIf="(formObject.itemChanged)">
            <app-detail-back-button [backRoute]="'../..'" class="d-block mt-4">
                <ng-container i18n>{{(formObject.firstName)}}</ng-container>
            </app-detail-back-button>
        </div>
      <app-form-group
      [hidden]="viewModel.loadingOpts?.isLoading"
      [formItems]="formItems"
      [formObject]="formObject"
      [styling]="formStyling"
      [options]="formOptions"
      (formCancelled)="cancel()"
      (formSubmitted)="formSubmitted($event)">
  </app-form-group>
      <app-loading [hidden]="!(viewModel.loadingOpts?.isLoading)"
                   [options]="viewModel.loadingOpts">
      </app-loading>
    </div>
  </app-content-container>


