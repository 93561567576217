import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../../models/base/base-view-model';
import {LoadingOptions} from '../../../../models/shared/loading-options';
import {BehaviorSubject, combineLatest, merge, Observable, of} from 'rxjs';
import {HydratedProgram} from '../../../../models/program/hydrated-program';
import {catchError, filter, map, switchMap, switchMapTo, tap} from 'rxjs/operators';
import {indicateOnNext} from '../../../../utils/observable.extensions';
import {ToastService} from '../../../../services/toast-service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminProgramDomainModel} from '../../../../domainModels/admin-program-domain-model';
import {LookupDomainModel} from '../../../../domainModels/lookup-domain-model';
import {ResourceDomainModel} from '../../../../domainModels/resource-domain-model';
import {ProgramFormObject} from '../../../../models/program/program-form-object';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ProductionType} from '../../../../models/lookup/production-type';
import {HydratedLeague} from '../../../../models/resources/hydrated-league';
import {HydratedVenue} from '../../../../models/resources/hydrated-venue';
import * as PapaParse from 'papaparse';
import {HydratedEvent} from '../../../../models/resources/hydrated-event';
import {ViewableProgramFormObject} from '../../../../models/program/viewable-program-form-object';
import {DatatableData} from '../../../../models/protocols/datatable-data';

@Injectable()
export class ProgramUploaderViewModel extends BaseViewModel {

  csvData: any[] = [];
    programFormObjects: ProgramFormObject[] = [];
  viewableFormObjects: ViewableProgramFormObject[] = [];

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  formErrorMessage = new BehaviorSubject<string>(null);

  loadingProgramMessage = $localize`Loading Program Details`;
  program$: BehaviorSubject<HydratedProgram> = new BehaviorSubject<HydratedProgram>(null);

  addNew: boolean = false;
  leagueId$ = new BehaviorSubject<number>(null);
  eventId$ = new BehaviorSubject<number>(null);
  venueId$ = new BehaviorSubject<number>(null);


  fetchProgramSubject = new BehaviorSubject<void>(null);
  fetchProgram = this.fetchProgramSubject
    .pipe(
      tap(() => this.loadingOpts.addRequest(this.loadingProgramMessage)),
      switchMapTo(this.activatedRoute.params),
      switchMap(params => {
        this.addNew = !params.programId;
        if (!!params.leagueId) {
          this.leagueId$.next(params.leagueId);
        } else if (!!params.venueId) {
          this.venueId$.next(params.venueId);
        } else if (!!params.eventId) {
          this.eventId$.next(params.eventId);
        }
        return this.addNew ? of(this.getDefaultHydratedLeagueProgram(false)) ||
          of(this.getDefaultHydratedEventProgram(false)) :
          this.domainModel.getHydratedProgram(params.programId);
      }),
      indicateOnNext(this.loadingOpts, this.loadingProgramMessage),
    ).subscribe(this.program$);
  productionTypes$ = this.lookupDomainModel.productionTypes.pipe(
    indicateOnNext(this.loadingOpts,'loading production types')
  );

  showStreamDetails$ = new BehaviorSubject<boolean>(false);
  fetchShowStreamDetails = this.program$.notNull().subscribe((p) => {
    this.showStreamDetails$.next(p.productionTypeId === ProductionType.PremiumId);
  }).addTo(this.subscriptions);

  hydratedLeague$ = new BehaviorSubject<HydratedLeague>(null);
  fetchLeague = this.leagueId$.pipe(
    filter(lId => !!lId),
    switchMap(lId => this.resourceDomainModel.getHydratedLeague(lId))).subscribe(this.hydratedLeague$);
  leagueHydratedVenues$ = this.hydratedLeague$.notNull().pipe(
    switchMap(league => this.resourceDomainModel.getHydratedVenuesByIds(league.associatedVenues.map(av => av.id)))
  );

  hydratedEvent$ = new BehaviorSubject<HydratedEvent>(null);
  fetchEvent = this.eventId$.pipe(
    filter(eId => !!eId),
    switchMap(eId => this.resourceDomainModel.getHydratedEvent(eId))).subscribe(this.hydratedEvent$);
  eventHydratedVenues$ = this.hydratedEvent$.notNull().pipe(
    switchMap(event => this.resourceDomainModel.getHydratedVenuesByIds(event.associatedVenues.map(av => av.id)))
  );

  hydratedVenue$ = new BehaviorSubject<HydratedVenue>(null);
  fetchVenue = this.venueId$.pipe(
    filter(vId => !!vId),
    switchMap(vId => this.resourceDomainModel.getHydratedVenue(vId))).subscribe(this.hydratedVenue$);

  filteredTeams$ = combineLatest([this.hydratedLeague$, this.hydratedEvent$, this.hydratedVenue$]).pipe(
    indicateOnNext(this.loadingOpts,'loading teams'),
    map(([league, event, venue]) => {
      return league?.associatedTeams ?? event?.associatedTeams ?? venue?.associatedTeams ?? [];
    }),
    map(teams => teams.filter(t => t.active)?.sort((a, b) => a.name.localeCompare(b.name)))
  );

  venueStreams$ = merge(this.leagueHydratedVenues$, this.eventHydratedVenues$, this.hydratedVenue$.notNull().pipe(map(v => [v]))).pipe(
    map((venues) => venues.flatMap(v => v.streams))
  );


  constructor(
    private toastService: ToastService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private domainModel: AdminProgramDomainModel,
    private lookupDomainModel: LookupDomainModel,
    private accountDomainModel: AccountDomainModel,
    private resourceDomainModel: ResourceDomainModel) {
    super();
    this.init();
  }

  init() {
    super.init();
  }

  parseCsvString(csv) {
    const parsedCsv =  PapaParse.parse(csv, {
      encoding: "UTF-8",
      // ... other PapaParse options
  });
    return parsedCsv.data;
  }

  getDefaultHydratedLeagueProgram(forLeague: boolean): HydratedProgram {
    const p = new HydratedProgram();
    p.productionTypeId = forLeague ? ProductionType.PremiumId : ProductionType.StandardId;
    return p;
  }

  getDefaultHydratedEventProgram(forEvent: boolean): HydratedProgram {
    const p = new HydratedProgram();
    p.productionTypeId = forEvent ? ProductionType.PremiumId : ProductionType.StandardId;
    return p;
  }

  saveProgram(formObject: ProgramFormObject,index: number): Observable<any>{
    formObject.leagueId = this.leagueId$.getValue();
    formObject.eventId = this.eventId$.getValue();
    // formObject.venueId = this.venueId$.getValue();
    if (this.hydratedLeague$.getValue()) {
      formObject.program.subscriptionPlanId = this.hydratedLeague$.getValue().subscriptionPlanId;
    }
    if (this.hydratedEvent$.getValue()) {
      formObject.program.subscriptionPlanId = this.hydratedEvent$.getValue().subscriptionPlanId;
    }
    if (this.hydratedVenue$.getValue()) {
      formObject.program.subscriptionPlanId = this.hydratedVenue$.getValue().subscriptionPlanId;
    }
    return this.domainModel.saveProgram(formObject).pipe(
      indicateOnNext(this.loadingOpts, $localize`Saving Program`),
      tap(() => {
      }),
      catchError(error => {
       formObject.index=index;
       return of({ success: false, formObject,error});
      })
    );
  }
}


