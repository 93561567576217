import {BaseModel} from '../base/base-model';
import {FavouriteResource, FavouriteResourceType} from '../protocols/favourite-resource';
import {Observable} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';
import {Selectable} from '../protocols/selectable';
import {DatatableData} from '../protocols/datatable-data';
import {SearchListable} from '../protocols/search-listable';
import {FormListable} from '../protocols/form-listable';

export class Event extends BaseModel implements FavouriteResource, Selectable, DatatableData, SearchListable, FormListable {
  id: number;
  name: string = null;
  abbreviation: string = null;
  subscriptionPlanId: string = null;
  active: boolean = true;
  eventStartDate: Date;

  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  imgSrc$: Observable<string | SafeResourceUrl>;

  onDeserialize() {
    super.onDeserialize();
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getFavouriteResourceName(): string {
    return this.name;
  }

  getFavouriteResourceId(): number {
    return this.id;
  }

  getFavouriteResourceType(): FavouriteResourceType {
    return 'event';
  }

  getSearchListableImgSrc$(): Observable<string | SafeResourceUrl> {
    return this.imgSrc$;
  }

  getSearchListablePrimaryKey(): string {
    return this.name;
  }

  getSearchListableSecondaryKey(): string {
    return this.abbreviation;
  }

  getSearchListableSubTitle(): string {
    return $localize`Event`;
  }

  getSearchListableTitle(): string {
    return this.name;
  }

  getEnabledStatus(): boolean {
    return this.active;
  }

  getListItemSubtitle(): string {
    return this.abbreviation;
  }

  getListItemTitle(): string {
    return this.name;
  }
}

