import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  FormInputItem,
  FormInputType,
  FormItemType,
  FormNumberInputType
} from '../../../../models/shared/stylesheet/form-input-item';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormGroupStyling} from '../../../../models/shared/stylesheet/form-group-styling';
import * as moment from 'moment';
import {SearchForValidatorDirective} from './validators/search-for-validator.directive';
import {PhoneValidatorDirective} from './validators/phone-validator.directive';
import {FormOptions} from 'src/app/models/shared/stylesheet/form-options';
import {BaseComponent} from '../../../../models/base/base-component';
import {Checkbox} from '../../../../models/shared/stylesheet/checkbox';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {FormListable} from '../../../../models/protocols/form-listable';
import {NumberUtils} from '../../../../utils/number-utils';
import {Address} from '../../../../models/location/address';
import {Radiobutton} from '../../../../models/shared/stylesheet/radiobutton';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormGroupComponent extends BaseComponent implements AfterViewInit, OnChanges, OnDestroy {

  // Inputs
  @Input() formItems: FormInputItem[] = [];
  @Input() formObject: any;
  @Input() styling: FormGroupStyling = new FormGroupStyling();
  @Input() options: FormOptions = new FormOptions();
  @Input() validateForm: EventEmitter<any> = new EventEmitter();
  @Input() updatedFormObject: EventEmitter<void> = new EventEmitter();
  @Input() hydrateInputObject: EventEmitter<any> = new EventEmitter();
  @Input() validateAndHydrate: EventEmitter<(valid: boolean) => void> = new EventEmitter<(valid: boolean) => void>();
  @Input() resetFormValues: EventEmitter<void> = new EventEmitter();
  @Input() showTertiaryButton: boolean = false;

  // Outputs
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  @Output() formCancelled: EventEmitter<any> = new EventEmitter();
  @Output() formReset: EventEmitter<any> = new EventEmitter();
  @Output() secondaryButtonPressed: EventEmitter<any> = new EventEmitter();
  @Output() tertiaryButtonPressed: EventEmitter<any> = new EventEmitter();
  @Output() formChanges: EventEmitter<any> = new EventEmitter();
  @Output() checkboxRowClicked: EventEmitter<void> = new EventEmitter();
  @Output() radiobuttonRowClicked: EventEmitter<void> = new EventEmitter();

  // Variables
  public form: FormGroup;
  public submitted: boolean = false;
  public itemTypes = FormItemType;
  public inputTypes = FormInputType;
  public checkboxValue: boolean = false;
  public radiobuttonValue: boolean = false;
  public settingUpForm: boolean = false;
  public initialValuesToEmit: [FormInputItem, any][] = [];

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
    super();
  }


  ngAfterViewInit() {
    // super.ngAfterViewInit();
    this.setFormInitialState();
    this.setFormObjectValues();
    this.settingUpForm = false;
    this.fireInitialValues();
  }

  setupViews() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.options?.checkboxBindingProperty?.length === 2) {
      this.checkboxValue = this.options.checkboxBindingProperty[1] || false;
    }
    if (this.options?.radiobuttonBindingProperty?.length === 2) {
      this.radiobuttonValue = this.options.radiobuttonBindingProperty[1] || false;
    }
    if (changes.formObject?.previousValue !== changes.formObject?.currentValue ||
      changes.formItems?.previousValue !== changes.formItems?.currentValue) {
      this.destroy();
      this.setupForm();
      this.setupBindings();
    }
    this.settingUpForm = false;
    this.fireInitialValues();
  }

  fireInitialValues() {
    if (this.initialValuesToEmit.length > 0) {
      // Emit any saved values during form setup
      if (this.options.emitInitialValuesAfterSetup) {
        this.initialValuesToEmit.forEach((initialValue) => {
          initialValue[0].valueChanged.next(initialValue[1]);
        });
      }
      this.initialValuesToEmit = [];
    }
  }

  setupBindings() {
    this.validateForm.subscribe(() => {
      this.setFormObjectValues();
      this.validate(this.form);
    }).addTo(this.subscriptions);

    this.validateAndHydrate.subscribe((callback: (valid: boolean) => void) => {
      this.validate(this.form);
      this.getPopulatedObject();
      callback(this.form.valid);
    }).addTo(this.subscriptions);

    this.updatedFormObject.subscribe(() => {
      this.setFormObjectValues();
      this.cd.detectChanges();
    }).addTo(this.subscriptions);

    this.resetFormValues.subscribe(() => {
      this.resetForm();
    }).addTo(this.subscriptions);

    // Bind to form input changes
    this.form.valueChanges.subscribe(() => {
      if (!this.settingUpForm) {
        // dont emit changes during form setup
        this.formChanges.emit();
      }
    }).addTo(this.subscriptions);
    // Create binding to hydrate input objects from form data
    this.hydrateInputObject.subscribe((_) => {
      this.getPopulatedObject();
    }).addTo(this.subscriptions);
  }

  setFormObjectValues() {
    this.initializeChipInputValues();

    // Update all the form input values from the formObject
    this.formItems.forEach((fi) => {
      if (fi.inputName && fi.inputType !== FormInputType.AddressAutoComplete) {
        const val = this.getBindingProperty(fi);
        this.form.get(fi.inputName).setValue(val);
        if (val) {
          this.form.get(fi.inputName).markAsTouched();
        }
        if (!this.settingUpForm && fi.itemType === FormItemType.Dropdown) {
          fi.valueChanged.next([val, fi.boundInputs]);
        }
      }
    });
  }

  setAutoCompleteFormValues(autoCompleteBindingProperty: string) {
    // Update all the form input values from the formObject
    this.formItems.filter(fi => fi.bindingProperty?.includes(`${autoCompleteBindingProperty}.`)).forEach((fi) => {
      if (fi.inputName && fi.inputType !== FormInputType.AddressAutoComplete) {
        const val = this.getBindingProperty(fi);
        this.form.get(fi.inputName).setValue(val);
        if (!this.settingUpForm && fi.itemType === FormItemType.Dropdown) {
          fi.valueChanged.next([val, fi.boundInputs]);
        }
      }
    });
  }

  setFormInitialState() {
    let hasInitialValue = false;
    this.formItems.forEach(i => {
      const initialValue = i.getValue();
      if (initialValue && initialValue !== '') {
        hasInitialValue = true;
        if (!this.settingUpForm) {
          i.valueChanged.next([initialValue, i.boundInputs]);
        } else {
          this.initialValuesToEmit.push([i, [initialValue, i.boundInputs]]);
        }
      }
    });
    if (hasInitialValue) {
      // Perform an initial validation on the form if it is not empty
      if (this.options.performNonEmptyInitialValidation) {
        this.validate(this.form);
      }
    }
  }

  isFormInputSet(fi: FormInputItem): boolean {
    const item = this.form.get(fi.inputName);
    const itemValue = item?.value;
    return item && itemValue !== null && itemValue !== undefined;
  }

  ngOnDestroy() {
    this.destroy();
    this.formItems = [];
    this.formSubmitted.complete();
    this.formCancelled.complete();
    this.secondaryButtonPressed.complete();
    this.tertiaryButtonPressed.complete();
    this.formChanges.complete();
  }

  setupForm() {
    this.settingUpForm = true;
    const controlsConfig = new Map<string, any>();
    this.formItems.forEach((fi) => {
      if (fi.inputName) {
        const validators = [];
        if (fi.required) {
          validators.push(Validators.required);
        }
        if (fi.minLength !== 0) {
          validators.push(Validators.minLength(fi.minLength));
        }
        if (fi.maxLength !== 0) {
          validators.push(Validators.maxLength(fi.maxLength));
        }
        if (fi.minValue !== -1) {
          validators.push(Validators.min(fi.minValue));
        }
        if (fi.maxValue !== -1) {
          validators.push(Validators.max(fi.maxValue));
        }
        if (fi.inputType === 'email') {
          validators.push(Validators.email);
        }
        if (fi.inputType === 'search' && !fi.useInputValueIfNoSearchableSelected) {
          validators.push(new SearchForValidatorDirective(fi.searchable));
        }
        if (fi.inputType === 'tel') {
          validators.push(new PhoneValidatorDirective());
        }
        if (fi.customValidator) {
          validators.push(fi.customValidator);
        }
        controlsConfig[fi.inputName] = [
          this.getBindingProperty(fi),
          validators
        ];
      }
    });
    this.form = this.formBuilder.group(controlsConfig);
    this.markPrefilledItemsAsTouched();
    this.cd.detectChanges();
    // Set form delegate for all Items
    this.formItems.forEach(i => {
      i.formDelegate = this.form;
      if (i.boundInputs) {
        // Ensure all bound props have reference to delegate
        i.boundInputs.forEach(bi => bi.formDelegate = this.form);
      }
    });

    setTimeout(() => {
      this.setupAddressAutoComplete();
    }, 0);
  }

  markPrefilledItemsAsTouched() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl && control.value) {
        control.markAsTouched({onlySelf: true});
      }
    });
  }

  resetForm() {
    this.submitted = false;
    this.form.reset();
    this.formItems.forEach((item) => {
      if (item.itemType === FormItemType.CheckboxGroup) {
        item.groupCheckboxOptions.touched = false;
      } else if (item.hasFormEditableValue()) {
        this.form.get(item.inputName).markAsPristine({onlySelf: true});
        this.form.get(item.inputName).markAsUntouched({onlySelf: true});
        this.form.get(item.inputName).setErrors(null);
      }
    });
    this.formReset.emit();
  }

  public canSubmitForm(): boolean {
    let canSumit = true;
    this.formItems.forEach((item) => {
      if (!item.canSubmit()) {
        canSumit = false;
      }
    });
    return canSumit;
  }

  cancelForm() {
    this.resetForm();
    this.formCancelled.emit();
  }

  public submitForm() {
    if (this.form.valid) {
      this.submitted = true;
      this.formSubmitted.emit(this.getPopulatedObject());
      if (this.options.clearOnSubmit) {
        this.resetForm();
      }
    } else {
      this.validate(this.form);
    }
  }

  getPopulatedObject(): any {
    this.formItems.forEach((item) => {
      if (!item.bindingProperty) {
        return;
      }
      let hasNestedProperty = false;
      if (item.bindingProperty.includes('.')) {
        hasNestedProperty = true;
        const bindingPropertyPath = item.bindingProperty.split('.');
        let nestedFormObject = this.formObject;
        bindingPropertyPath.forEach((p) => {
          if (nestedFormObject && nestedFormObject.hasOwnProperty(p)) {
            nestedFormObject = nestedFormObject[p];
          } else {
            hasNestedProperty = false;
          }
        });
      }
      if (this.formObject && this.formObject.hasOwnProperty(item.bindingProperty) || hasNestedProperty) {
        if (item.inputType === FormInputType.Date) {
          // Parse Date
          this.setBindingProperty(item.bindingProperty, moment(this.form.get(item.inputName).value, 'YYYY-MM-DD').toDate());
        } else if (item.inputType === FormInputType.Search) {
          let setVal = item.searchable.find(i =>
            i.lookupKey === this.form.get(item.inputName).value
          )?.value;
          if (!setVal && item.useInputValueIfNoSearchableSelected) {
            setVal = this.form.get(item.inputName).value;
          }
          this.setBindingProperty(item.bindingProperty, setVal);
        } else if (item.inputType === FormInputType.Tel) {
          const telephone = this.form.get(item.inputName).value;
          const setVal = telephone.replaceAll('+', '')
            .replaceAll(' ', '').replaceAll('.', '').replaceAll('-', '')
            .replaceAll('(', '').replaceAll(')', '');
          this.setBindingProperty(item.bindingProperty, setVal);
        } else if (item.inputType === FormInputType.AddressAutoComplete) {
          // this should just populate other fields
        } else if (!!item.numberInputType) {
          switch (item.numberInputType) {
            case FormNumberInputType.Integer:
              this.setBindingProperty(item.bindingProperty, NumberUtils.formIntegerCustomParser(this.form.get(item.inputName).value));
              break;
            case FormNumberInputType.Decimal:
              this.setBindingProperty(item.bindingProperty, NumberUtils.formFloatCustomParser(this.form.get(item.inputName).value));
              break;
          }
        } else if (item.chipInput) {
          this.setBindingProperty(item.bindingProperty, item.chipInputValues.join(', '));
        } else if (item.itemType === FormItemType.RadioGroup) {
          this.setBindingProperty(item.bindingProperty, item.groupRadios.find(r => r.selected).id);
        } else if (item.customValueParser || item.itemType === FormItemType.CheckboxGroup) {
          // Handle custom encoding
          if (item.itemType === FormItemType.CheckboxGroup) {
            this.setBindingProperty(item.bindingProperty, item.getCheckBoxItems(item.groupCheckboxes));
          } else {
            this.setBindingProperty(item.bindingProperty, item.customValueParser(this.form.get(item.inputName).value));
          }
        } else {
          // Standard encode from form value
          this.setBindingProperty(item.bindingProperty, this.form.get(item.inputName).value);
        }
      }
    });
    if (this.options.includeEndFormCheckbox) {
      if (this.formObject.hasOwnProperty(this.options.checkboxBindingProperty[0])) {
        this.formObject[this.options.checkboxBindingProperty[0]] = this.checkboxValue;
      }
    }
    if (this.options.includeEndFormRadiobutton) {
      if (this.formObject.hasOwnProperty(this.options.radiobuttonBindingProperty[0])) {
        this.formObject[this.options.radiobuttonBindingProperty[0]] = this.radiobuttonValue;
      }
    }
    return this.formObject;
  }

  private getBindingProperty(fi: FormInputItem): any {
    if (!this.formObject || fi.inputType === FormInputType.AddressAutoComplete) {
      return null;
    }
    let val = null;
    if (fi.bindingProperty.includes('.')) {
      const bindingPropertyPath = fi.bindingProperty.split('.');
      let nestedFormObject = this.formObject;
      bindingPropertyPath.forEach((p, i) => {
        if (nestedFormObject.hasOwnProperty(p)) {
          if (i === bindingPropertyPath.length - 1) {
            // Last element, set the value
            val = nestedFormObject[p];
          } else if (nestedFormObject[p]) {
            nestedFormObject = nestedFormObject[p];
          } else {
            val = null;
          }
        }
      });
    } else {
      val = this.formObject[fi.bindingProperty];
    }
    // Get dropdown Object match
    if (fi.itemType === FormItemType.Dropdown && val instanceof Object) {
      const dropdownMatch = fi.dropdownOptions?.find(d => typeof val.getSelectionUniqueIdentifier !== 'undefined'
        && d.getSelectionUniqueIdentifier() === val.getSelectionUniqueIdentifier());
      if (dropdownMatch) {
        val = dropdownMatch.getSelectionValue();
      }
    } else if (fi.itemType === FormItemType.Switch && typeof val !== 'boolean') {
      val = val === 'true';
    } else if (fi.inputType === FormInputType.Search) {
      const searchInputValue = val;
      val = fi.searchable.find(i =>
        i.value === searchInputValue
      )?.lookupKey;

      if (!val && fi.useInputValueIfNoSearchableSelected) {
        val = searchInputValue;
      }
    } else if (val && !!fi.numberInputType) {
      // number inputs should populate in the form as strings
      val = val.toString();
    }
    return val;
  }

  private setBindingProperty(bindingProperty: string, val: any) {
    if (bindingProperty.includes('.')) {
      const bindingPropertyPath = bindingProperty.split('.');
      let nestedFormObject = this.formObject;
      bindingPropertyPath.forEach((p, i) => {
        if (nestedFormObject.hasOwnProperty(p)) {
          if (i === bindingPropertyPath.length - 1) {
            // Last element, set the value
            nestedFormObject[p] = val;
          } else {
            nestedFormObject = nestedFormObject[p];
          }
        }
      });
    } else {
      this.formObject[bindingProperty] = val;
    }
  }

  validate(form: any) {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validate(control);
      }
    });
    // Mark any checkbox groups as touched
    this.formItems.forEach((item) => {
      if (item.itemType === FormItemType.CheckboxGroup) {
        item.groupCheckboxOptions.touched = true;
      }
    });
  }

  shouldDisplayAbandonDialog(): boolean {
    // Get any checkbox groups that have been touched
    const touchedCheckboxGroup = this.formItems.filter(fi => {
      return fi.itemType === FormItemType.CheckboxGroup && fi.groupCheckboxOptions.touched;
    }).length > 0;
    // Get all dirty form controls
    const dirty = Object.keys(this.form.controls).map(field => {
      const control = this.form.get(field);
      return control?.dirty;
    });
    return dirty.some((v) => v === true) || touchedCheckboxGroup;
  }

  resetAbandonDialogState() {
    this.formItems.forEach((item) => {
      if (item.itemType === FormItemType.CheckboxGroup) {
        item.groupCheckboxOptions.touched = false;
      } else if (item.hasFormEditableValue()) {
        this.form.get(item.inputName).markAsPristine({onlySelf: true});
      }
    });
  }

  handleInputKeyUp(e, item: FormInputItem) {
    let code;
    if (e.key !== undefined) {
      code = e.key;
    } else if (e.keyIdentifier !== undefined) {
      code = e.keyIdentifier;
    } else if (e.keyCode !== undefined) {
      code = e.keyCode;
    }
    if (item.chipInput && (code === 13 || code === 'Enter' || code === ',' || code === ' ')) {
      this.addChipInputValue(item);
    } else if ((code === 13 || code === 'Enter') && this.options.submitOnEnter) {
      this.submitForm();
    }

    item.handleKeyUpValueChanged();
  }


  checkboxItemClicked(checked: boolean, item: FormInputItem) {
    this.form.get(item.inputName).setValue(checked);
    item.handleValueChanged();
  }

  checkboxClicked(rem: boolean) {
    this.checkboxValue = rem;
    this.getPopulatedObject();
    this.formChanges.next();
  }

  groupedCheckboxesChanged(item: FormInputItem, checkboxes: Checkbox[]) {
    this.validate(this.form);
    item.groupCheckboxOptions.touched = true;
    if (item.groupCheckboxesChanged) {
      item.groupCheckboxesChanged(checkboxes);
    }
  }

  groupedRadiosChanged(item: FormInputItem, radios: Radiobutton[]) {
    this.validate(this.form);
    item.groupRadioOptions.touched = true;
    if (item.groupRadiosChanged) {
      item.groupRadiosChanged(radios);
    }
  }

  getTooltipPlacement(itemIndex: number): PlacementArray {
    if (this.styling.tooltipPosition) {
      return this.styling.tooltipPosition;
    } else {
      return itemIndex % 2 === 0 ? ['right', 'auto', 'top', 'bottom'] : ['left', 'auto', 'top', 'bottom'];
    }
  }

  checkboxRowButtonClicked() {
    this.checkboxRowClicked.emit();
  }

  setupAddressAutoComplete() {
    this.formItems.filter(f => f.inputType === FormInputType.AddressAutoComplete).forEach(formItem => {
      const element = document.getElementById(formItem.inputName) as HTMLInputElement;
      element.setAttribute('autocomplete', 'new-password');
      const autocomplete = new google.maps.places.Autocomplete(element,
        {
          componentRestrictions: {country: ['US', 'CA']},
          types: ['address']  // 'establishment' / 'address' / 'geocode'
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const autoCompleteAddress = Address.fromPlaceResult(place);
        this.setBindingProperty(formItem.bindingProperty, autoCompleteAddress);
        this.setAutoCompleteFormValues(formItem.bindingProperty);
        this.markPrefilledItemsAsTouched();
        this.cd.detectChanges();
      });
    });
  }

  getListItems(inputItem: FormInputItem): FormListable[] {
    return (this.getBindingProperty(inputItem) as FormListable[])?.filter(i => !i.itemDeleted);
  }

  // Chip Input items
  initializeChipInputValues() {
    this.formItems.filter(fi => fi.chipInput).forEach((fi) => {
      const val = this.getBindingProperty(fi) as string;
      if (val.length > 0) {
        fi.chipInputValues = val.split(',');
        this.setBindingProperty(fi.bindingProperty, '');
      }
    });
  }

  removeChipValue(formInput: FormInputItem, chipValue: string) {
    formInput.chipInputValues.splice(formInput.chipInputValues.indexOf(chipValue), 1);
  }

  addChipInputValue(fi: FormInputItem) {
    if (!fi.chipInput) {
      return;
    }
    const formControl = this.form.get(fi.inputName);
    const val = formControl.value?.replace(/\W/g, '') as string;
    formControl.setValue(val);
    if (val?.length > 0 && formControl.valid) {
      formControl.setValue('');
      fi.chipInputValues.push(val.toUpperCase());
      this.setBindingProperty(fi.bindingProperty, '');
    }
  }
}
